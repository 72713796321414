
export default {
  props: ["obj"],
  data() {
    return {
      //喷漆颜色列表
      loading_msg: "",
      width: 0,
      vflag: true,
      sceneTitle: "",
    };
  },
  methods: {
    close() {
      setTimeout(() => {
        this.$refs.load.style.opacity = 0;
      }, 100);
      setTimeout(() => {
        this.$refs.load.style.display = "none";
      }, 500);
    },
  },

  created() {
    document.o2vr.on_loading_msg = (a, b, c) => {
      console.log(a, b, c);
      this.loading_msg = `${b}${c}`;
      this.width = b;
      if (b == 100) {
        this.vflag = false;
        //   setTimeout(() => {
        //     this.$refs.load.style.opacity = 0;
        //   }, 1000);
        //   setTimeout(() => {
        //     this.$refs.load.style.display = "none";
        //   }, 2000);
      }
    };
  },
  mounted() {
    console.log(this.obj);
  },

  watch: {
    obj(val) {
      console.log(val, "objobjobj");
      if (val.sceneTitle) {
        this.sceneTitle = document.showroom.utf8to16(
          document.showroom.decode64(val.sceneTitle)
        );
      }
    },
  },
};
