
import Bus from "../../common/js/bus";

export default {
  data() {
    return {
      touch: false,
      flag: false,
      flag1: false,
      vedio: "",
      vedioFlag: false,
      img: "",
      imgFlag: false,
      scale: 1,
      functionBox: [
        {
          font: "放大",
          img: require("../assets/img/big.png"),
        },
        {
          font: "缩小",
          img: require("../assets/img/small.png"),
        },
        {
          font: "还原",
          img: require("../assets/img/re.png"),
        },
      ],
      position: {
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
      },
      moveobj: {},
      title: "",
      text: "",
      audio: "",
      list: [],
      value: 0,
      iframeFlag: false,
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], //倍速控制
        autoplay: true, //是否自动播放
        muted: false, //是否静音播放
        loop: false, //是否循环播放
        preload: "auto",
        language: "zh-CN", //语言，还要引入对应的文件
        aspectRatio: "16:9", //比例
        fluid: true,
        sources: [
          {
            type: "",
            src: "", //url地址
          },
        ],
        poster: "", //你的封面地址
        notSupportedMessage: "此视频暂无法播放，请稍后再试",
        controlBar: {
          timeDivider: true,
          durationDisplay: true, //剩余时间是否显示
          remainingTimeDisplay: false, //剩余时间是否显示，有一个即可
          fullscreenToggle: true, //全屏按钮
        },
      },
      link: "",
    };
  },
  methods: {
    checkWidth() {
      setTimeout(() => {
        var img = document.getElementById("img");

        var img1 = document.getElementById("img1");
        console.log(img.clientHeight, img.clientWidth);
        if (img.clientHeight < img.clientWidth) {
          img.className = "img";
          img1.className = "img";
        } else {
          img.className = "img1";
          img1.className = "img1";
        }

        return true;
      }, 10);
    },
    close() {
      this.flag = false;
      this.vedioFlag = false;
      this.imgFlag = false;
      this.iframeFlag = false;
      this.flag1 = false;
      this.scale = 1;
      console.log("关闭");
      this.value = 0;
      this.position = {
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
      };
      if (this.audio) {
        Bus.$emit("music", true);
      }
      // Bus.$emit("music", true);
    },
    f(e) {
      var scale = this.scale;
      if (e == 0) {
        this.scale = scale * 1.2;
      } else if (e == 1) {
        this.scale = scale * 0.8;
      } else {
        this.scale = 1;
        this.position = {
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
        };
      }
    },
    start(e) {
      console.log(e);
      document.body.addEventListener("mousemove", this.move);
      document.body.addEventListener("mouseup", this.end);

      document.body.addEventListener("touchmove", this.move);
      document.body.addEventListener("touchend", this.end);
    },
    move(e) {
      this.moveobj = e;
    },
    end() {
      this.moveobj = {};
      document.body.removeEventListener("mousemove", this.move);
      document.body.removeEventListener("mouseup", this.end);
      document.body.addEventListener("touchmove", this.move);
      document.body.addEventListener("touchend", this.end);
    },
    changeBox() {
      this.flag1 = true;
      setTimeout(() => {
        document.getElementById("box").style.cssText =
          "width: 100%;height: 70px;background-color: transparent;position: absolute;left: 0;top: 0;z-index: 999;";
      }, 1);
      console.log("进入浏览");
    },
    checkFlag(e) {
      console.log(e);
      if (e.name.split(".")[1] != "mp4") {
        return true;
      } else {
        Bus.$emit("music", false);
        return false;
      }
    },
  },
  created() {
    Bus.$on("point", (target) => {
      console.log(target);

      this.position = {
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
      };
      //展板1
      this.flag = true;
      this.imgFlag = true;
      this.iframeFlag = false;
      // this.list = target.res_list;
      var list = target.res_list;
      target.res_list.forEach((e, i) => {
        if (!e.name) {
          list.splice(i, 1);
        }
      });
      this.list = list;

      console.log(this.list);
      if (!this.checkFlag(this.list[this.value])) {
        this.playerOptions.sources[0].src = this.list[this.value].url;
      }
      if (target.text) {
        // this.text = document.showroom.utf8to16(document.showroom.decode64(target.text));
        this.text = target.text;
      } else {
        this.text = "";
      }

      if (target.title) {
        // this.title = document.showroom.utf8to16(document.showroom.decode64(target.title));

        this.title = target.title;
      } else {
        this.title = "";
      }

      if (target.voice_res) {
        this.audio = target.voice_res.url;
        Bus.$emit("music", false);
      } else {
        this.audio = "";
      }
    });

    Bus.$on("hypherLink", (target) => {
      this.imgFlag = false;
      this.iframeFlag = false;
      var link = document.showroom.utf8to16(
        document.showroom.decode64(target.Hyperlinks)
      );

      function CheckUrl(a) {
        var regstr = /http/; //var reg = new RegExp("^http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- ./?%&=]*)?$");
        console.log(regstr.test(a));
        if (regstr.test(a)) {
          return a;
        } else {
          return `https://${a}`;
        }
      }
      console.log(target.linkValue);
      if (target.linkValue == 2) {
        console.log(link, "open");
        window.open(CheckUrl(link));
      } else if (target.linkValue == 1) {
        console.log(link);
        window.location.replace(CheckUrl(link));

        // window.location.replace(
        //   "http://192.168.1.41:8080/#/?id=10149&secret=MTAxNDk=&ratio=1024"
        // );

        setTimeout(() => {
          location.reload();
        }, 10);
      } else {
        console.log(this.link);
        this.link = CheckUrl(link);
        this.flag = true;
        this.iframeFlag = true;
      }
    });
  },
  watch: {
    flag(val) {
      Bus.$emit("flag", val);
    },
    moveobj: {
      handler(newValue, oldValue) {
        if (newValue.clientX && oldValue.clientX) {
          this.position.top = this.position.top + newValue.clientY - oldValue.clientY;
          this.position.left = this.position.left + newValue.clientX - oldValue.clientX;
        }

        if (newValue.touches[0].clientX && oldValue.touches[0].clientX) {
          if ((document.o2.cameraControls.orientation = "landscape")) {
            this.position.top =
              this.position.top -
              newValue.touches[0].clientX +
              oldValue.touches[0].clientX;

            this.position.left =
              this.position.left +
              newValue.touches[0].clientY -
              oldValue.touches[0].clientY;
          } else {
            this.position.top =
              this.position.top +
              newValue.touches[0].clientY -
              oldValue.touches[0].clientY;
            this.position.left =
              this.position.left +
              newValue.touches[0].clientX -
              oldValue.touches[0].clientX;
          }
        }
      },
      deep: true,
    },

    value(val) {
      if (!this.checkFlag(this.list[val])) {
        this.playerOptions.sources[0].src = this.list[val].url;
      }
    },
  },
  mounted() {
    // document.addEventListener("wheel", this.onMouseWheel, false);
  },
};
