
import wx from "weixin-js-sdk";

import { getScene, get_scene_user, add_popular, config } from "../assets/api/index";
import QS from "qs";

import axios from "axios";

import Bus from "../../common/js/bus.js";
export default {
  name: "MainView",
  components: {},
  data() {
    return {
      desc: "3D View",
      O2: {},
      selObj: {},
      url: "",
      obj: {},
      id: "",
      flag: false,
      vip: "",
    };
  },
  methods: {
    readTextFile(file, callback) {
      var rawFile = new XMLHttpRequest();
      rawFile.overrideMimeType("application/json");
      rawFile.open("GET", file, true);
      rawFile.onreadystatechange = function () {
        if (rawFile.readyState === 4 && rawFile.status == "200") {
          callback(rawFile.responseText);
        }
      };
      rawFile.send(null);
    },
    load(res) {
      console.log(JSON.parse(res.data.edit_info))
      document.showroom.load(
        Object.assign(JSON.parse(res.data.edit_info), { url: res.data.url })
      );
      this.obj = document.showroom.room;

      this.wxConfig();

      var w = document.documentElement.clientWidth;
      if (w < 500) {
        document.o2.cameraControls.orientation = "landscape";
        console.log(document.o2.cameraControls.orientation);
      }

      if (document.showroom.room.sceneTitle) {
        var title = document.showroom.utf8to16(
          document.showroom.decode64(document.showroom.room.sceneTitle)
        );
        document.title = title;
      } else {
        document.title = "三维编辑器";
      }
      Bus.$emit("obj", this.obj);
      document.o2.set_edit_mode(false);
    },
    changeSence() {
      var that = this;

      if (document.o2.type == "local") {
        this.readTextFile("public/packge.json", function (text) {
          var data = JSON.parse(text);
          console.log(data);
          var id = data.id;
          that.id = id;
          document.showroom.room_id = id;
          var secret = data.secret;

          const query = QS.parse(location.hash.substring(3));
          var ratio = query.ratio;
          if (id == window.atob(secret)) {
            get_scene_user({ id }).then((res) => {
              console.log(res);
              var guid = res.data.guid;
              document.showroom.guid = guid;
              axios
                .get("https://api.mvpmeta.com/api/user/public/vip", {
                  params: { guid },
                })
                .then((res) => {
                  document.showroom.vip = res.data.data.vip;
                  that.vip = res.data.data.vip;
                });
            });

            getScene({ id, reg_preview: ratio }).then((res) => {
              if (JSON.parse(res.data.edit_info).pswFlag) {
                var psw = JSON.parse(res.data.edit_info).psw;

                that
                  .$prompt("请输入访问密码", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    inputErrorMessage: "邮箱格式不正确",
                  })
                  .then(({ value }) => {
                    if (value == psw) {
                      that.$message({
                        type: "success",
                        message: "验证成功,即将进入场景 ",
                      });
                      that.load(res);
                    } else {
                      that.$message({
                        type: "info",
                        message: "密码错误,您没有权限访问",
                      });
                    }
                  })
                  .catch(() => {
                    that.$message({
                      type: "info",
                      message: "取消输入",
                    });
                  });
              } else {
                that.load(res);
              }
            });
          } else {
            that.$notify({
              title: "警告",
              message: "您暂无权限浏览",
              type: "warning",
            });
          }
        });
      } else {
        const query = QS.parse(location.hash.substring(3));
        var id = query.id;
        this.id = id;

        var ratio = query.ratio;
        document.showroom.room_id = id;
        var secret = query.secret;
        add_popular({ id: this.id });
        if (id == window.atob(secret)) {
          get_scene_user({ id }).then((res) => {
            console.log(res);
            var guid = res.data.guid;
            document.showroom.guid = guid;
            axios
              .get("https://api.mvpmeta.com/api/user/public/vip", { params: { guid } })
              .then((res) => {
                document.showroom.vip = res.data.data.vip;
                this.vip = res.data.data.vip;
                this.flag = true;
              });
          });

          getScene({ id, reg_preview: ratio }).then((res) => {
            if (JSON.parse(res.data.edit_info).pswFlag) {
              var psw = JSON.parse(res.data.edit_info).psw;

              this.$prompt("请输入访问密码", "提示", {
                confirmButtonText: "确定",
                inputErrorMessage: "邮箱格式不正确",
              })
                .then(({ value }) => {
                  if (value == psw) {
                    this.$message({
                      type: "success",
                      message: "验证成功,即将进入场景 ",
                    });
                    this.load(res);
                  } else {
                    this.$message({
                      type: "info",
                      message: "密码错误,您没有权限访问",
                    });
                  }
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "取消输入",
                  });
                });
            } else {
              this.load(res);
            }
          });
        } else {
          this.$notify({
            title: "警告",
            message: "您暂无权限浏览",
            type: "warning",
          });
        }
      }
    },

    wxConfig() {
      var that = this;

      console.log(wx);
      config({ url: window.btoa(window.location.href) }).then((rs) => {
        wx.config({
          debug: false,
          appId: rs.data.appId,
          timestamp: rs.data.timestamp,
          nonceStr: rs.data.nonceStr,
          signature: rs.data.signature,
          jsApiList: [
            "checkJsApi",
            "ready",
            "updateAppMessageShareData",
            "updateTimelineShareData",
          ],
        });
        wx.checkJsApi({
          jsApiList: [
            "checkJsApi",
            "updateAppMessageShareData",
            "ready",
            "updateTimelineShareData",
          ],
          success: function (res) {
            console.log(JSON.stringify(res));
          },
        });
        wx.ready(function () {
          // alert("微信环境");
          //需在用户可能点击分享按钮前就先调用
          wx.updateAppMessageShareData({
            title: document.title, // 分享标题
            desc: "共建共享天朗气清的网络家园", // 分享描述
            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: that.obj.logo.preview, // 分享图标
            success: function () {
              // 设置成功
              console.log("设置成功", "wx.updateAppMessageShareDat");
            },
          });

          wx.updateTimelineShareData({
            title: document.title, // 分享标题
            desc: "共建共享天朗气清的网络家园", // 分享描述
            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: that.obj.logo.preview, // 分享图标
            success: function () {
              // 设置成功
              console.log("设置成功", "wx.updateTimelineShareData");
            },
          });
        });
        wx.error(function (res) {
          console.log(res.errMsg); //错误提示 也没提示，也不会进断点
        });
      });
    },
  },
  beforeCreate() {},
  created() {
    this.changeSence();
  },
  mounted() {},
};
