
import MainView from "./components/MainView.vue";
import client from "./components/client.vue";
import Box from "../showroom/components/box.vue";
import HandShake from "./components/handShake.vue";
import mobileTest from "./components/mobileTest.vue";
import o2vr from "./components/banner.vue";
import Play from "./../showroom/components/playNew_repair.vue";
import Function from "./components/function.vue";
import Login from "./components/login.vue";
import Login1 from "./components/login1.vue";
import Bus from "../common/js/bus.js";
export default {
  components: {
    MainView,
    HandShake,
    Box,
    mobileTest,
    o2vr,
    Play,
    Function,
    Login,
    Login1,
    client,
  },
  data() {
    return {
      flag: false,
      screen: false,
      infoFlag: true,
      obj: {},
      id: "",
      vip: 0,
    };
  },
  methods: {
    listen() {
      setTimeout(() => {
        var w = document.documentElement.clientWidth;
        if (w < 500) {
          document.o2.cameraControls.orientation = "landscape";
          console.log(document.o2.cameraControls.orientation);
        } else {
          document.o2.cameraControls.orientation = "";
        }
           console.log(
        document.o2.cameraControls.orientation,
        document.documentElement.clientWidth
      );
      }, 500);

   
    },
  },
  created() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      this.flag = true;
    }
    Bus.$on("obj", (e) => {
      this.obj = e;
      console.log(this.obj);
      this.id = document.showroom.room_id;
      this.vip = document.showroom.vip;
      console.log(this.vip);
    });

    window.addEventListener("orientationchange", this.listen);

    // window.onhashchange = function () {
    //   location.reload();
    // };

    // document.body.addEventListener("touchmove", function (e) {
    //   console.log(e, "禁止禁止");
    //   e.preventDefault();
    // }); //阻止默认的处理方式(阻止下拉滑动的效果) }

    // document.addEventListener(
    //   "touchmove",
    //   function (e) {
    //     e.preventDefault();
    //   },
    //   { passive: false }
    // );
    // document.removeEventListener(
    //   "touchmove",
    //   function (e) {
    //     e.preventDefault();
    //   },
    //   { passive: false }
    // );
  },
  watch: {
    // screen(v) {
    //   if (v) {
    //     location.reload();
    //   }
    // },
  },
};
