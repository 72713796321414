import Bus from '../../common/js/bus'
export default {
  namespaced: true,
  state() {
    return {
      ws: '', //websokset
      msgList: [[]], //消息列表
      cheatList: [{ name: '世界', id: 0 }], //聊天人列表
      value: 0, //当前与哪个人物聊天
      role: {}, //角色信息
      timmer: '', //计时器
      playerList: [], //人物列表
      user_power: 0, //0=游客 1=普通用户 2=认证用户 3=企业用户 4=企业管理员 5=总管理
      pageMsgList: [],
      sharename: '', //主持人名字，
      worldMsgList: [], //世界消息
      privateChatMsgList: {}, //私聊消息库建立 【"id-id":[]】
      unreadMsgP: [], //私人未读消息数据"[sendId,recvId]"
      unreadMsgW: '' //发送者的id
      // connectInfo: {
      //   //请求消息头
      //   CMD_VISITOR_LOGIN: 1059,
      //   CMD_COMMON_HEARTBEAT: 1000, //心跳 1000,ping
      //   CMD_COMMON_POSITION: 3, //实时同步位置信息 3,positions
      //   CMD_BUFFER_POSITION: 4, //缓存位置信息 4,positions
      //   CMD_COMMON_DEBUG: 1100, //用来调试
      //   CMD_USER_LOGIN: 1050, //登录 1050,name,id,info
      //   CMD_USER_CHATS: 1051, //公屏聊天 1051,messaged
      //   CMD_USER_CHAT: 1052, //私聊 1052,rev_id,message
      //   CMD_USER_QUIT: 1053, //退出 1053,null
      //   CMD_USER_SIT_DOWN: 1054, //坐下 1054,sitname,id
      //   CMD_USER_STAND_UP: 1055, //起立 1055,sitname,id
      //   CMD_USER_CUSTOM: 1056, //用户广播自定义消息 1056,custom_msg
      //   CMD_USER_CUSTOM_SEND: 1057, //用户向指定id自定义消息 1057,rev_id,custom_msg
      //   CMD_USER_LOGIN2: 1058, //游客登录 1058,name
      //   CMD_ITEM_DISPLAY: 1070, //隐藏显示物体 1070,itemname
      //   //响应消息头
      //   MSG_RELOGIN: 198, //重复登录,现有账户退出。198,id
      //   MSG_NULL: 200, //无消息头
      //   MSG_LOGIN_SUCCESS: 201, //登录成功 201,id
      //   MSG_NEWPLAYER: 202, //新用户加入 202,playerinfo
      //   MSG_QUIT: 203, //退出 203,id
      //   MSG_POS: 204, //位置信息  204,id,positions
      //   MSG_BUF_POS: 205, //缓存位置信息 205,positions0,positions1,....
      //   MSG_PLAYERS: 206, //用户列表 202,json_encode(players)  $player = array("fd" => $this->fd,"id" => $pid,"name" => $cur_name,"info" => $info);
      //   MSG_CHATS: 207, //公屏聊天 207,id,msg
      //   MSG_CHAT: 208, //私聊 208,send_id,rev_id,msg
      //   MSG_SITS: 209, //当前所有座位信息 209,json_encode(sits) $sit[$sitname] = id;
      //   MSG_SIT_DOWN: 210, //坐下 210,sitname,id
      //   MSG_STAND_UP: 211, //起立 211,sitname,id
      //   MSG_CUSTOM: 212, //用户自定义消息 212,id,custom_msg
      //   MSG_CLEAR_SITDOWNS: 214, //制清理入座信息 214,
      //   MSG_ITEM_DISPLAY: 220, //用来调试 220,itemname,0; //hide 0 /display 1
      //   MSG_DEBUG: 300, //用来调试
      //   MSG_ALL_CHATS: 221, //历史群聊记录 221,json_string
      //   MSG_MY_CHAT: 222 //历史私聊记录 221,json_string
      // }
    }
  },

  mutations: {
    //websocket链接
    login(state, data) {
      let that = this
      state.role = data
      var msg
      let ws = document.o2.ws
      let responseCmd = ws.responseCmd

      //历史群聊记录 221,json_string
      ws.on(responseCmd.MSG_ALL_CHATS, (str) => {
        let arr = JSON.parse(str)
        if (arr.length > 0) {
          arr.forEach((item) => {
            let msg = item.split(',')
            state.worldMsgList.push({
              name: msg[0],
              msg: msg[2]
            })
          })
        }
      })

      //历史私聊记录 222,json_string
      ws.on(responseCmd.MSG_MY_CHAT, (str) => {
        let arr = JSON.parse(str)
        if (arr.length > 0) {
          arr.forEach((item) => {
            let msg = item.split(',')
            that.commit('chat/pcml', { sendname: msg[0], reName: msg[1], msg: msg[4], sendid: msg[2], reid: msg[3] })
          })
        }
      })

      // msg = evt.data.split(',')
      // Bus.$emit('playermsg', evt)
      // 登录成功
      ws.on(responseCmd.MSG_LOGIN_SUCCESS, (str) => {
        let arr = str.split(',')
        state.role.id = arr[0]
        document.client.login(arr[0])
        state.role.fd = arr[1]
        Bus.$emit('loginS', '')
      })

      // 获取用户列表
      ws.on(responseCmd.MSG_PLAYERS, (str) => {
        let arr = JSON.parse(str)
        arr.forEach((element) => {
          element.audioMuted = true
          element.videoMuted = true
          element.hasAudio = false
          element.hasVideo = false
          element.disable = false
          state.playerList.push(element)
        })
      })

      // 增加新用户
      ws.on(responseCmd.MSG_NEWPLAYER, (str) => {
        let arr = str.split(',')
        state.playerList.push({
          id: arr[0],
          name: arr[1],
          vip: arr[2],
          fd: arr[3],
          audioMuted: true,
          videoMuted: true,
          hasAudio: false,
          hasVideo: false,
          disable: false
        })
      })

      // 用户退出
      ws.on(responseCmd.MSG_QUIT, (str) => {
        var value = state.playerList.findIndex((el) => el.id == str)
        state.playerList.splice(value, 1)
      })

      // 获取公屏聊天列表
      ws.on(responseCmd.MSG_CHATS, (str) => {
        let msg = str.split(',')
        var user = state.playerList.filter((el) => el.id == msg[0])
        var name = ''
        if (user.length) {
          name = user[0].name
        }
        if (msg.length == 4 && msg[3] == 'msgPage') {
          state.pageMsgList.push({ name, msg: msg[1] })
        } else {
          state.unreadMsgW = ''
          setTimeout(() => {
            state.unreadMsgW = msg[0]
          }, 0)
        }

        state.msgList[0].push({
          name,
          msg: msg[1]
        })

        state.worldMsgList.push({
          name,
          msg: msg[1]
        })
      })

      //获取私聊列表
      ws.on(responseCmd.MSG_CHAT, (str) => {
        let arr = str.split(',')
        var name = state.playerList.filter((el) => el.id == arr[0])[0].name
        state.msgList.push([])
        var sendid = state.playerList.filter((el) => el.id == arr[0])[0].name
        var reid = state.playerList.filter((el) => el.id == arr[1])[0].name
        var m = arr[2]
        var value = arr[3] * 1
        //privateChatMsgList: {}, //私聊消息库建立 【"id-id":[]】
        that.commit('chat/pcml', { sendname: sendid, reName: reid, msg: m, sendid: arr[0], reid: arr[1] })
        // 私人未读消息数据"sendId-recvId"
        state.unreadMsgP.push([arr[0], arr[1]])
      })

      //重复登录
      ws.on(responseCmd.MSG_RELOGIN, (str) => {
        let arr = JSON.parse(str)
        if (state.role.fd == arr[0]) {
          localStorage.removeItem('info')
          location.reload()
          done()
        }
        var value1 = state.playerList.findIndex((el) => el.fd == arr[0])
        state.playerList.splice(value1, 1)
      })

      //自定义消息
      ws.on(responseCmd.MSG_CUSTOM, (str) => {
        let msg = JSON.parse(str)
        if (msg[1].split('-')[0] == 'editInfo') {
          let name = msg[1].split('-')[1]
          let model = msg[1].split('-')[2]
          that.playerList.forEach((f, i) => {
            if (f.id == msg[0]) {
              that.playerList.splice(i, 1, {
                fd: f.fd,
                id: f.id,
                name: name,
                vip: model,
                roomid: f.roomid,
                role: f.role,
                userId: f.userId,
                audioMuted: f.audioMuted,
                videoMuted: f.videoMuted,
                hasAudio: f.hasAudio,
                hasVideo: f.hasVideo,
                disable: f.disable
              })
            }
          })
          Bus.$emit('updateSharename')
        }
        if (msg[1].includes('setName')) {
          let roleName = msg[2]
            .split(':')[1]
            .replace(/\"/g, '')
            .replace('}', '')
          state.role.name = roleName
        }
        if (msg[1].split('-')[0] == 'jinshipin') {
          Bus.$emit('jinshipin', {
            id: msg[0],
            flag: msg[1].split('-')[1] == '1' ? true : false
          })
        }
        if (msg[1].split('-')[0] == 'jinyuyin') {
          Bus.$emit('jinyuyin', {
            id: msg[0],
            flag: msg[1].split('-')[1] == '1' ? true : false
          })
        }
        if (msg[1].split('-')[0] == 'outpersonSingle') {
          Bus.$emit('outpersonSingle', {
            id: msg[1].split('-')[1]
          })
        }
        if (msg[1].split('-')[0] == 'jinyuyinbyone') {
          Bus.$emit('jinyuyinbyone', {
            id: msg[1].split('-')[2],
            flag: msg[1].split('-')[1] == '1' ? true : false
          })
        }
        if (msg[1].split('-')[0] == 'jinshipinbyone') {
          Bus.$emit('jinshipinbyone', {
            id: msg[1].split('-')[2],
            flag: msg[1].split('-')[1] == '1' ? true : false
          })
        }
      })
    },

    // 匹配名称
    searchName(state, data) {
      var name = state.playerList.filter((el) => el.id == data)[0].name
      return name
    },
    // 发送消息
    send(state, data) {
      document.o2.ws.send(data)
    },
    //修改value--当前与哪个人物聊天
    setValue(state, data) {
      console.log(data)
      state.value = data.value
    },
    //删去主页消息显示
    setMsgPageValue(state) {
      state.pageMsgList.shift()
    },
    // 主持人名称
    searchNameByZhuChiRen(state, data) {
      if (!data) return (state.sharename = data)
      var name = state.playerList.filter((el) => el.id == data)[0].name
      state.sharename = name
    },
    //修改每个用户是否语音状态
    editVA(state, data) {
      data.forEach((stream) => {
        state.playerList.find(function (value) {
          if (value.userId == stream.userId) {
            value.audioMuted = stream.audioMuted
            value.hasAudio = stream.hasAudio
            value.hasVideo = stream.hasVideo
            value.videoMuted = stream.videoMuted
          }
        })
      })
    },
    //踢掉用户
    kickOut(state, data) {
      let index = state.playerList.findIndex((item) => item.id == data.id)
      state.playerList.splice(index, 1)
    },
    //privateChatMsgList: {}, //私聊消息库建立 【"id-id":[]】
    pcml(state, data) {
      let key1 = data.sendid + '-' + data.reid
      let key2 = data.reid + '-' + data.sendid
      if (!state.privateChatMsgList.hasOwnProperty(key1) && !state.privateChatMsgList.hasOwnProperty(key2)) {
        state.privateChatMsgList[key1] = []
        state.privateChatMsgList[key1].push({ name: data.sendname, msg: data.msg })
      } else {
        if (state.privateChatMsgList.hasOwnProperty(key1)) {
          state.privateChatMsgList[key1].push({ name: data.sendname, msg: data.msg })
        } else {
          state.privateChatMsgList[key2].push({ name: data.sendname, msg: data.msg })
        }
      }
      // console.log(state.privateChatMsgList, '++++')
      Bus.$emit('privateChatMsgListChange')
    },
    //移出已读的信息
    shiftOutUnread(state, data) {
      // debugger
      if (state.unreadMsgP.length == 0) return
      let index = state.unreadMsgP.findIndex((item) => (item[0] == data[0] && item[1] == data[1]) || (item[0] == data[1] && item[1] == data[0]))
      if (index != -1) state.unreadMsgP.splice(index, 1)
    },
    //恢复未读记录
    restoreUnread(state, data) {
      // state.unreadMsgP = data.unreadMsgP
      // state.unreadMsgW = data.unreadMsgW
      // Bus.$emit('unreadMsgPChange', data.unreadMsgP)
      // Bus.$emit('unreadMsgWChange', data.unreadMsgW)
    }
  },

  getters: {},

  actions: {}
}
