
import * as THREE from "three/build/three.module";
import Bus from "../../common/js/bus";
import { player } from "../../dongsheng/player";
import bus from "../../common/js/bus";
import { CSS3DObject, CSS3DSprite } from "three/examples/jsm/renderers/CSS3DRenderer";
export default {
  props: ["itemName", "floor", "speed"],
  data() {
    return { flag: false };
  },
  mounted() {
    var that = this;
    Bus.$on("flag", (target) => {
      that.flag = target;
    });
    let bInitPlayers = false;
    let mtl_highlight;
    let highlight_object;
    let editType = null;
    let bGuideActived = false;
    let videoTex;
    let old_map;
    let old_video_object;
    let old_audio_object;
    var showroom = document.showroom;
    let people_radius = 100; //角色的碰撞半径
    let down_speed = 0; //下落速度
    var colli;
    let colli_height = 200;
    var last_click_pos = new THREE.Vector2();
    var last_click_time;
    var bPaused = false;
    var bEditMode = true;
    let old_pos, old_eye;
    var touch_time;
    var ws;
    let target_angle = 0;
    let last_clip_name;
    let posPlayer = new THREE.Vector3();
    let multi_sprite_sel = [];

    var itemName = this.itemName;
    var floor = this.floor;
    var player_name = "";
    var room_id = 0;
    var ws_pos_cold_down = 0;
    var player_id = 0;
    var bLoginSuccess = false;
    var last_pos_send;
    console.log(floor);
    var viewHeight = 2000;
    var moveSpd = 2000;
    var _o2 = document.o2vr;
    _o2.char_urls = [];
    _o2.char_urls.push(
      "https://o2public.oss-cn-beijing.aliyuncs.com/%E5%85%83%E5%B1%85/%E7%94%B71.zip"
    );
    _o2.char_urls.push("https://o2public.oss-cn-beijing.aliyuncs.com/元居/女2.zip");
    _o2.char_urls.push(
      "https://o2public.oss-cn-beijing.aliyuncs.com/%E5%85%83%E5%B1%85/%E7%94%B72.zip"
    );
    _o2.char_urls.push("https://o2public.oss-cn-beijing.aliyuncs.com/元居/女1.zip");
    _o2.char_urls.push(
      "https://o2public.oss-cn-beijing.aliyuncs.com/%E5%85%83%E5%B1%85/%E7%94%B73.zip"
    );
    _o2.char_urls.push(
      "https://o2res.oss-cn-beijing.aliyuncs.com/webres/%E5%AE%98%E7%BD%91%E5%B0%8F%E4%BA%BA/lady3.zip?OSSAccessKeyId=LTAI4G4zQbTdurvEbr1rR9zS&Expires=36001642575325&Signature=dy%2BaFK8nOY7AfqkPrKhOic8lUgs%3D"
    );
    _o2.view_length = 2000;

    Bus.$on("viewHeight", (e) => {
      viewHeight = e;
      console.log(viewHeight);
    });

    Bus.$on("moveSpd", (e) => {
      moveSpd = e;
      console.log(moveSpd);
    });
    // Bus.$on('stream', (arr) => {
    //   if (Array.isArray(arr) && arr.length == 2) {
    //     let cmd = arr[0]
    //     let id = arr[1]
    //     if (cmd == 'local_stream') {
    //       if (!_o2.player_video) {
    //         _o2.player_video = _o2.add_video_stream_to_char(
    //           _o2.player,
    //           'local_stream'
    //         )
    //       }
    //       if (_o2.player_video) {
    //         _o2.player_video.style.display = 'none'
    //       }
    //       //
    //     } else if (cmd == 'remote_stream' && _o2.players) {
    //       for (let i = 0; i < _o2.players.length; i++) {
    //         let p = _o2.players[i]
    //         if (p.id == id) {
    //           if (!p.player_video) {
    //             p.player_video = _o2.add_video_stream_to_char(p.object, id)
    //           } else {
    //             p.player_video.style.display = 'block'
    //           }
    //           return
    //         }
    //       }
    //     }
    //   }
    // })
    // Bus.$on('vmute', (arr) => {
    // if (Array.isArray(arr)) {
    //   let val = arr[0];
    //   let name = arr[1];
    //   if (_o2.player_video) _o2.player_video.style.display = val ? "block" : "none";
    // }
    // })
    if (!itemName) {
      itemName = "Item";
    }

    var bKeyCtrl = true;
    var bAutoMove = false;
    var targetAutoMove = new THREE.Vector3();

    var moveDir = new THREE.Vector3();
    var that = this;
    var mouse_down = false;
    function onMouseDown(event) {
      mouse_down = true;
      touch_time = Date.now();
    }

    function onMouseMove(event) {
      if (bEditMode || mouse_down) return;
      if (showroom.click_able_items) {
        //let intersects = _o2.ray_test2(showroom.click_able_items, event);
        let intersects = _o2.ray_test(event);

        if (intersects.length > 0) {
          let obj = intersects[0].object;
          if (obj.bind_id != undefined) {
            if (!highlight_object) {
              highlight_object = obj.clone();
              highlight_object.material = mtl_highlight;
              _o2.scene.add(highlight_object);
            } else {
              if (obj.showbox.res_list.length == 0 && obj.showbox.Hyperlinks == "")
                return;
              document.body.style.cursor = "pointer";

              highlight_object.visible = true;
              highlight_object.geometry = obj.geometry;
              highlight_object.position.set(0, 0, 0);
              highlight_object.scale.set(1, 1, 1);
              highlight_object.rotation.set(0, 0, 0);
              highlight_object.applyMatrix4(obj.matrixWorld);
            }
          } else {
            if (highlight_object) highlight_object.visible = false;
            document.body.style.cursor = "";
          }
        } else {
          if (highlight_object) highlight_object.visible = false;
          document.body.style.cursor = "";
        }
      }
    }

    function Base64() {
      // private property
      var _keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

      // public method for encoding
      this.encode = function (input) {
        var output = "";
        var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
        var i = 0;
        input = _utf8_encode(input);
        while (i < input.length) {
          chr1 = input.charCodeAt(i++);
          chr2 = input.charCodeAt(i++);
          chr3 = input.charCodeAt(i++);
          enc1 = chr1 >> 2;
          enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
          enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
          enc4 = chr3 & 63;
          if (isNaN(chr2)) {
            enc3 = enc4 = 64;
          } else if (isNaN(chr3)) {
            enc4 = 64;
          }
          output =
            output +
            _keyStr.charAt(enc1) +
            _keyStr.charAt(enc2) +
            _keyStr.charAt(enc3) +
            _keyStr.charAt(enc4);
        }
        return output;
      };
      // public method for decoding
      this.decode = function (input) {
        var output = "";
        var chr1, chr2, chr3;
        var enc1, enc2, enc3, enc4;
        var i = 0;
        input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");
        while (i < input.length) {
          enc1 = _keyStr.indexOf(input.charAt(i++));
          enc2 = _keyStr.indexOf(input.charAt(i++));
          enc3 = _keyStr.indexOf(input.charAt(i++));
          enc4 = _keyStr.indexOf(input.charAt(i++));
          chr1 = (enc1 << 2) | (enc2 >> 4);
          chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
          chr3 = ((enc3 & 3) << 6) | enc4;
          output = output + String.fromCharCode(chr1);
          if (enc3 != 64) {
            output = output + String.fromCharCode(chr2);
          }
          if (enc4 != 64) {
            output = output + String.fromCharCode(chr3);
          }
        }
        output = _utf8_decode(output);
        return output;
      };

      // private method for UTF-8 encoding
      let _utf8_encode = function (string) {
        string = string.replace(/\r\n/g, "\n");
        var utftext = "";
        for (var n = 0; n < string.length; n++) {
          var c = string.charCodeAt(n);
          if (c < 128) {
            utftext += String.fromCharCode(c);
          } else if (c > 127 && c < 2048) {
            utftext += String.fromCharCode((c >> 6) | 192);
            utftext += String.fromCharCode((c & 63) | 128);
          } else {
            utftext += String.fromCharCode((c >> 12) | 224);
            utftext += String.fromCharCode(((c >> 6) & 63) | 128);
            utftext += String.fromCharCode((c & 63) | 128);
          }
        }
        return utftext;
      };

      // private method for UTF-8 decoding
      let _utf8_decode = function (utftext) {
        var string = "";
        var i = 0;
        let c2 = 0;
        let c1 = 0;
        let c3 = 0;
        var c = (c1 = c2 = 0);
        while (i < utftext.length) {
          c = utftext.charCodeAt(i);
          if (c < 128) {
            string += String.fromCharCode(c);
            i++;
          } else if (c > 191 && c < 224) {
            c2 = utftext.charCodeAt(i + 1);
            string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
            i += 2;
          } else {
            c2 = utftext.charCodeAt(i + 1);
            c3 = utftext.charCodeAt(i + 2);
            string += String.fromCharCode(
              ((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63)
            );
            i += 3;
          }
        }
        return string;
      };
    }

    function send_position_to_server(bForce) {
      if (!ws || !bLoginSuccess) return;
      let angle2 = Math.ceil((target_angle * 180) / 3.1415);
      let str =
        "pos," +
        Math.ceil(posPlayer.x) +
        "," +
        Math.ceil(posPlayer.y) +
        "," +
        Math.ceil(posPlayer.z) +
        "," +
        angle2;
      if (str != last_pos_send) {
        last_pos_send = str;
        ws.send(str);
      } else {
        if (bForce) {
          last_pos_send = str;
          ws.send(str);
        }
      }
    }

    function stop_ctrl(bStop) {
      if (bPaused == bStop) return;
      if (!bPaused) {
        old_pos = _o2.cameraControls.target.clone();
        old_eye = _o2.camera.position.clone();
      } else {
        _o2.cameraControls.target.set(old_pos.x, old_pos.y, old_pos.z);
        _o2.camera.position.set(old_eye.x, old_eye.y, old_eye.z);
      }
      bPaused = bStop;
    }
    _o2.stop_ctrl = stop_ctrl;
    function onItemClick(intersect) {
      let object = intersect.object;
      //判断地面
      let parent_root = object;
      while (parent_root.parent && parent_root.parent != _o2.scene) {
        parent_root = parent_root.parent;
      }
      if (object.guide_view) {
        active_guide(false);
        toView(object.guide_view);
        bGuideActived = false;
        bPaused = false;
        return;
      }
      //判断展项
      if (showroom) {
        let sb = showroom.test_bind_click(object);
        trigger_showbox(sb, object);
      }

      if (parent_root && parent_root.name == "ground") {
        move_to(intersect.point.x, intersect.point.z);
        return;
      }
      Bus.$emit("onItemClick", object.name);
    }
    function trigger_showbox(sb, object) {
      if (old_audio_object && old_audio_object != object) {
        old_audio_object = null;
        showroom.removeAudio();
      }

      if (old_video_object && old_video_object != object) {
        //停止视频播放
        old_video_object.material.map = old_map;
        old_video_object = null;
        _o2.stop_video_tex();
      }

      if (old_video_object && old_video_object == object) {
        //停止视频播放
        old_video_object.material.map = old_map;
        old_video_object = null;
        _o2.stop_video_tex();
        return;
      }

      if (old_audio_object && old_audio_object == object) {
        old_audio_object = null;
        showroom.removeAudio();
        return;
      }

      if (sb) {
        console.log(object);
        console.log(sb);
        if (sb.linkFlag == 2) {
          Bus.$emit("hypherLink", sb);
        }
        if ((sb.bPopUp || sb.res_list.length > 1) && sb.linkFlag == 1) {
          Bus.$emit("point", sb);
        } else {
          let info = sb.res_list[0];
          if (info && info.type == "video") {
            //视频直接播放
            if (old_video_object != object) {
              let tex = _o2.get_video_tex(info.url, true);
              if (tex) {
                if (old_video_object) {
                  old_video_object.material.map = old_map;
                }
                videoTex = tex;
                old_video_object = object;
                old_map = object.material.map;
                object.material.map = videoTex;
              }
            }
          }
          let voice = sb.voice_res;
          if (voice && voice.type == "audio") {
            //音频播放
            if (old_audio_object != object) {
              showroom.playAudio(voice.url);
              old_audio_object = object;
            }
          }
        }
      }
    }
    function getMovePoint(intersect) {
      if (that.flag) return;
      let _o2 = document.o2;
      let pos = intersect.position;
      return pos;
    }

    function getView() {
      return (
        Math.round(_o2.cameraControls.target.x) +
        "," +
        Math.round(_o2.cameraControls.target.y) +
        "," +
        Math.round(_o2.cameraControls.target.z) +
        "," +
        Math.round(_o2.camera.position.x) +
        "," +
        Math.round(_o2.camera.position.y) +
        "," +
        Math.round(_o2.camera.position.z) +
        "," +
        Math.round(posPlayer.x) +
        "," +
        Math.round(posPlayer.y) +
        "," +
        Math.round(posPlayer.z)
      );
    }
    _o2.getView = getView;

    function toView(item) {
      console.log(item);
      var view = item.split(",");
      if (view[6] && view[7] && view[8]) {
        posPlayer.set(parseInt(view[6]), parseInt(view[7]), parseInt(view[8]));
      }
      _o2.cameraControls.target.set(
        parseInt(view[0]),
        parseInt(view[1]),
        parseInt(view[2])
      );
      _o2.camera.position.set(parseInt(view[3]), parseInt(view[4]), parseInt(view[5]));
    }
    _o2.toView = toView;

    function onMouseUp(event) {
      mouse_down = false;
      // if (that.flag) return;
      let _o2 = document.o2;

      let dnow = Date.now();
      let dst = dnow - touch_time;
      if (dst > 200) {
        return;
      }

      //双击判断
      if (1) {
        if (last_click_time) {
          let offset = new THREE.Vector2();
          offset.set(event.offsetX, event.offsetY);
          let dst2 = dnow - last_click_time;
          offset.sub(last_click_pos);
          let len = offset.length();
          //console.log("dbtest,", len, dst2);
          if (len < 100 && dst2 < 500) {
            //点击点
            on_db_click(event);
            last_click_time = 0;
            return;
          }
        }
      }

      last_click_pos.set(event.offsetX, event.offsetY);
      last_click_time = dnow;
      on_click(event);
    }

    function set_edit_type(type) {
      editType = type;
      //热点区域的显示
      if (type == 4) {
        if (showroom.hot_area_parent) {
          showroom.hot_area_parent.visible = true;
        }
      } else {
        if (showroom.hot_area_parent) {
          showroom.hot_area_parent.visible = false;
        }
      }
      if (type == 3) {
        //取消相机远近控制
        oldfar = _o2.cameraControls.maxDistance;
        _o2.cameraControls.maxDistance = 100000;
      } else {
        if (oldfar) _o2.cameraControls.maxDistance = oldfar;
      }
      console.log("set_edit_type", type);
    }
    _o2.set_edit_type = set_edit_type;
    function on_db_click(event) {
      // if (bEditMode && editType == 1) {
      //   //模型编辑
      //   let intersects = _o2.ray_test(event);
      //   if (intersects.length > 0) {
      //     console.log("模型编辑", intersects[0]);

      //     //判断素材是不是用户自己布置的
      //     let user_object;
      //     let obj2 = intersects[0].object;
      //     while (obj2 && obj2 != _o2.scene) {
      //       if (obj2.user_model == true) {
      //         user_object = obj2;
      //         break;
      //       }
      //       obj2 = obj2.parent;
      //     }
      //     if (user_object) {
      //       showroom.set_gizmo_object(user_object);
      //       if (!showroom.gizmo_mode) {
      //         showroom.set_gizmo_mode(1);
      //       }
      //     } else {
      //       showroom.set_gizmo_object(null);
      //     }
      //   } else {
      //     showroom.set_gizmo_object(null);
      //   }
      // }
      // //拾取热点
      // // if (bEditMode && editType == 4) {
      // //   //模型编辑
      // //   let intersects = _o2.ray_test(event);
      // //   if (intersects.length > 0) {
      // //     console.log("热点拾取", intersects[0]);
      // //     //判断素材是不是用户自己布置的
      // //     let user_object;
      // //     let obj2 = intersects[0].object;
      // //     if (obj2.hot) user_object = obj2;
      // //     if (user_object) {
      // //       showroom.set_gizmo_object(user_object);
      // //       Bus.$emit("size", user_object.hot_scale);
      // //       console.log("热点大小:" + user_object.hot_scale);
      // //       if (!showroom.gizmo_mode) {
      // //         showroom.set_gizmo_mode(1);
      // //       }
      // //     } else {
      // //       showroom.set_gizmo_object(null);
      // //     }
      // //   } else {
      // //     showroom.set_gizmo_object(null);
      // //   }
      // // }
      // // if (bEditMode) {
      // //   //展项选择
      // //   let intersects = _o2.ray_test(event);
      // //   if (intersects.length > 0) {
      // //     //判断素材是不是用户自己布置的
      // //     let showbox;
      // //     let user_object;
      // //     let obj = intersects[0].object;
      // //     console.log(obj);
      // //     showbox = obj.showbox;
      // //     if (showbox) {
      // //       console.log("弹窗编辑", showbox);
      // //       Bus.$emit("boxChange", showbox);
      // //     }
      // //   }
      // // }
      if (bEditMode) {
        //展项选择
        let intersects = _o2.ray_test(event);
        if (intersects.length > 0) {
          //判断素材是不是用户自己布置的
          let obj = intersects[0].object;

          //判断鼠标工具修改
          {
            let user_object;
            if (obj.bHot_area || obj.hot) {
              user_object = obj;
            } else {
              let obj2 = obj;
              while (obj2 && obj2 != _o2.scene) {
                if (obj2.user_model == true) {
                  user_object = obj2;
                  break;
                }
                obj2 = obj2.parent;
              }
            }
            if (user_object) {
              showroom.set_gizmo_object(user_object);
              showroom.set_gizmo_mode(1);
            } else {
              showroom.set_gizmo_object(null);
            }
          }

          {
            //弹窗
            let showbox = obj.showbox;
            //热点区域拾取
            {
              if (obj.bHot_area && obj.aht) {
                console.log("热点区域编辑", obj.aht);
                Bus.$emit("hot_area_edit", obj.aht);
                return;
              }
            }
            if (obj.hot) {
              Bus.$emit("size", obj.hot_scale);
            }
            console.log(obj.showbox);
            if (!showbox) {
              Bus.$emit("boxChange", null);
              Bus.$emit("hot_area_edit", null);
            } else {
              console.log("弹窗编辑", showbox);
              Bus.$emit("boxChange", showbox);
            }
          }
        }
      }
    }
    function do_multi_sprite_click(event) {
      if (!event.ctrlKey) return;
      if (!bEditMode) return;
      if (editType != 4) return;
      let intersects = _o2.ray_test(event);
      if (intersects.length > 0) {
        let obj = intersects[0].object;
        if (obj.hot) {
          if (obj.multi_seled) {
            obj.material.color.setRGB(1, 1, 1);
            obj.multi_seled = false;
          } else {
            obj.multi_seled = true;
            obj.material.color.setRGB(1, 0, 0);
          }
        }
      }
    }
    function on_click(event) {
      do_multi_sprite_click(event);
      if (bEditMode) return;
      let intersects;
      if (bGuideActived && showroom.guide_obj)
        intersects = _o2.ray_test2(showroom.guide_obj.children, event);
      else intersects = _o2.ray_test(event);
      if (intersects.length > 0) {
        onItemClick(intersects[0]);
      }
    }
    function add_player(id, name, num) {
      //Bus派发num给展厅
      Bus.$emit("num", 1);
      let url = _o2.char_urls[num];
      console.log(url);
      // alert(url);
      let promise = _o2.import_object_url(url);
      // let promise = _o2.import_object_url("https://o2vr.net/players/man2.zip");

      promise.then((obj3) => {
        if (obj3) {
          obj3.visible = false; //先隐藏，第一次有位置以后再出现
          obj3.name = toString(id);
          _o2.renew_all_uuid(obj3);
          if (!_o2.players) {
            _o2.players_object = new THREE.Object3D();
            _o2.players = [];
            _o2.scene.add(_o2.players_object);
          }
          _o2.players_object.add(obj3);
          _o2.add_lable_to_char(obj3, name);
          let player = {};
          player.object = obj3;
          player.id = id;

          var base = new Base64();
          player.name = base.decode(name);
          for (let i = 0; i < obj3.children.length; i++) {
            if (obj3.children[i].type == "Group") {
              player.char = obj3.children[i];
              break;
            }
          }
          // _o2.add_lable_to_char(obj3, player.name);
          //_o2.play_skeleton_clip(obj3);
          player.target = new THREE.Vector3();
          player.angle = 0;
          _o2.players.push(player);
          console.log("new_player", player);
          send_position_to_server(1);
        }
      });
    }
    function update_player_position(id_player, posx, posy, posz, angle) {
      if (_o2.players && _o2.players.length > 0) {
        for (let i = 0; i < _o2.players.length; i++) {
          let player = _o2.players[i];
          if (player.id == id_player && player.object) {
            player.target.x = posx;
            player.target.z = posz;
            player.target.y = posy;
            player.angle = (angle * 3.1415) / 180;
            if (!player.object.visible) {
              player.object.visible = true;
              player.object.position.set(posx, posy, posz);
            }
          }
        }
      }
    }
    function move_to(x, y) {
      bAutoMove = true;
      targetAutoMove.x = x;
      targetAutoMove.y = posPlayer.y;
      targetAutoMove.z = y;
    }
    function remove_player(id) {
      if (_o2.players) {
        let left_players = [];
        for (let i = 0; i < _o2.players.length; i++) {
          let player = _o2.players[i];
          if (player.id == id) {
            _o2.players_object.remove(player.object);
          } else {
            left_players.push(player);
          }
        }
        _o2.players = left_players;
      }
    }

    _o2.move_to = move_to;
    function init() {
      colli = _o2.search_obj_by_name("colli");
      mtl_highlight = new THREE.MeshBasicMaterial({
        color: 0xffaa00,
        transparent: true,
        blending: THREE.AdditiveBlending,
      });
      mtl_highlight.depthTest = false;
      mtl_highlight.opacity = 0.1;
      mtl_highlight.depthWrite = false;

      //加载角色
      var url;
      console.log(document.showroom.room.roleValue);
      role_id = document.showroom.room.roleValue ? document.showroom.room.roleValue : 0;
      _o2.role_id = role_id;
      let promise = _o2.import_object_url(_o2.char_urls[role_id]);
      promise.then((obj3) => {
        if (obj3) {
          _o2.renew_all_uuid(obj3);
          _o2.scene.add(obj3);
          if (_o2.player) {
            _o2.scene.remove(_o2.player);
            // _o2.player.dispose();
          }
          _o2.player = obj3;
          if (obj3.type == "Group") {
            _o2.char = obj3;
          } else {
            for (let i = 0; i < obj3.children.length; i++) {
              if (obj3.children[i].type == "Group") {
                _o2.char = obj3.children[i];
                break;
              }
            }
          }
          var flag = document.showroom.room.viewFlag
            ? document.showroom.room.viewFlag
            : false;
          console.log(flag, "人物显示");
          document.o2.setViewMode(!flag);
          //login("zcl",100,2);
        }
      });
      _o2.stop_switch_camera();
      // _o2.cameraControls.target.set(-190, 2163, -1106);
      // _o2.camera.position.set(-413, 2554, -8251);
      // posPlayer.set(0, 0, 0);
    }
    _o2.init_play = init;

    Bus.$on("playermsg", (evt) => {
      var received_msg = evt.data;
      // console.log(received_msg);
      let pos = received_msg.indexOf(",");
      if (pos > 0) {
        let cmd = received_msg.substr(0, pos);
        let param = received_msg.substr(pos + 1, received_msg.length - pos - 1);
        console.log(cmd, param);
        if (cmd == "logsuccess") {
          ws = this.$chat.ws;
          role_id = this.$chat.role.rid;
          let arrStr = param.split(",");
          if (!arrStr || arrStr.length < 2) return;
          player_id = arrStr[0];
          player_name = arrStr[0];
          Bus.$on("player", (target) => {
            if (bInitPlayers) return;
            if (Array.isArray(target.players)) {
              bInitPlayers = true;
              console.log(target);
              target.players.forEach((p) => {
                if (p.name != player_name) add_player(p.name, p.name, p.info);
              });
              Bus.$emit("playerInited");
            }
          });
          if (_o2.player) {
            var flag = document.showroom.room.viewFlag
              ? document.showroom.room.viewFlag
              : false;
            document.o2.setViewMode(!flag);
            if (_o2.role_id == role_id) {
              _o2.add_lable_to_char(_o2.player, player_name);
            } else {
              _o2.role_id = role_id;
              for (let i = 0; i < _o2.player.children.length; i++) {
                let o = _o2.player.children[i];
                if (o.type.indexOf("Mesh") != -1) {
                  o.geometry.dispose();
                  o.material.dispose();
                  break;
                }
              }
              _o2.scene.remove(_o2.player);
              let url = _o2.char_urls[role_id];
              let promise = _o2.import_object_url(url);
              promise.then((obj3) => {
                if (obj3) {
                  _o2.renew_all_uuid(obj3);
                  _o2.scene.add(obj3);
                  _o2.player = obj3;
                  _o2.add_lable_to_char(_o2.player, player_name);
                  if (obj3.type == "Group") {
                    _o2.char = obj3;
                  } else {
                    for (let i = 0; i < obj3.children.length; i++) {
                      if (obj3.children[i].type == "Group") {
                        _o2.char = obj3.children[i];
                        break;
                      }
                    }
                  }
                  //if (_o2.init_play) _o2.init_play();
                }
              });
            }
          }
          // var str = player_name;
          // var base = new Base64();
          // var result = base.encode(str);
          // ws.send("login," + result + "," + room_id.toString() + "," + role_id);
          // console.log("login," + result + "," + room_id.toString() + "," + role_id);

          // ws.send("login," + result + "," + room_id.toString() + "," + role_id);
          bLoginSuccess = true;
        }
        if (cmd == "new_player") {
          let pos2 = param.indexOf(",");

          var list = received_msg.split(",");
          var num;
          if (list.length > 3) {
            num = parseInt(list[3]);
            let new_player_id = list[1];
            let new_player_name = list[1];
            //console.log(new_player_id,new_player_name);
            if (new_player_id != player_id) {
              add_player(new_player_id, new_player_name, num);
            }
          }
        }
        if (cmd == "player_quit") {
          let arrStr = param.split(",");
          if (arrStr && arrStr.length == 2) {
            let player_id = arrStr[0];
            remove_player(player_id);
            Bus.$emit("num", -1);
          }
        }
        if (cmd == "pos") {
          let pos2 = param.indexOf(",");
          let left = param;
          if (pos2 > 0) {
            console.log(left);
            let strid = left.substr(0, pos2);
            let id_player = strid;
            left = left.substr(pos2 + 1, left.length - pos2 - 1);
            let arrStr = left.split(",");
            if (arrStr && arrStr.length == 4) {
              let posx = parseFloat(arrStr[0]);
              let posy = parseFloat(arrStr[1]);
              let posz = parseFloat(arrStr[2]);
              let angle = parseFloat(arrStr[3]);
              update_player_position(id_player, posx, posy, posz, angle);
            }
            // pos2 = left.indexOf(",");
            // if (pos2 > 0) {
            //   let posx = parseInt(left.substr(0, pos2));
            //   left = left.substr(pos2 + 1, left.length - pos2 - 1);
            //   pos2 = left.indexOf(",");
            //   if (pos2 > 0) {
            //     let posy = parseInt(left.substr(0, pos2));
            //     left = left.substr(pos2 + 1, left.length - pos2 - 1);
            //     let angle = parseInt(left);
            //     // console.log(id_player,posx,posy,angle);
            //     update_player_position(id_player, posx, posy, angle);
            //   }
            // }
          }
        }
        let arrMsg = received_msg.split(",");
        if (arrMsg.length >= 2) {
          let cmd2 = arrMsg[1];
          if (cmd2 == "vmute") {
            if (arrMsg && arrMsg.length == 4) {
              let name = arrMsg[2];
              let val = arrMsg[3];
              val = val == "true";
              if (_o2.players) {
                for (let i = 0; i < _o2.players.length; i++) {
                  const p = _o2.players[i];
                  if (p.id == name) {
                    //setTimeout(() => {
                    // if(!p.player_video){
                    //   p.player_video = _o2.add_video_stream_to_char(p.object,name);
                    // }
                    // if(p.player_video)
                    // p.player_video.visible = val;
                    if (val) {
                      p.player_video = _o2.add_video_stream_to_char(p.object, name);
                    }
                    if (p.player_video) {
                      p.player_video.style.display = val ? "block" : "none";
                    }
                    //}, 1000);
                    return;
                  }
                }
              }
            }
          }
        }
        if (received_msg.indexOf("transfer") >= 0) {
          let parms = received_msg.split(",");
          //console.log(arr[0],arr[1],arr[2],arr[3],arr[4]);
          if (parms && parms.length == 5) {
            let idStr = parms[0].substr(2, parms[0].length - 2);
            let id = Number(idStr);
            let x = Number(parms[2]);
            let z = Number(parms[3]);
            let a = Number(parms[4]);
            if (_o2.players) {
              let len = _o2.players.length;
              for (let i = 0; i < len; i++) {
                let player = _o2.players[i];
                if (player.id == id && player.object) {
                  player.target.x = x;
                  player.target.z = z;
                  player.target.y = (a * 3.1415) / 180;
                  player.object.position.set(x, 0, z);
                  break;
                }
              }
            }
          }
        }
      }
    });
    function WebSocketInit() {
      if ("WebSocket" in window) {
        console.log("support WebSocket!");
        ws.onopen = function () {
          console.log("WebSocket OnOpen...");
        };
        ws.onmessage = function (evt) {
          var received_msg = evt.data;
          // console.log(received_msg);
          let pos = received_msg.indexOf(",");
          if (pos > 0) {
            let cmd = received_msg.substr(0, pos);
            let param = received_msg.substr(pos + 1, received_msg.length - pos - 1);
            console.log(cmd, param);
            if (cmd == "logsuccess") {
              ws = that.$chat.ws;
              role_id = that.$chat.role.rid;
              let arrStr = param.split(",");
              if (!arrStr || arrStr.length < 2) return;
              player_id = arrStr[0];
              player_name = arrStr[0];
              Bus.$on("player", (target) => {
                if (bInitPlayers) return;
                if (Array.isArray(target.players)) {
                  bInitPlayers = true;
                  console.log(target);
                  target.players.forEach((p) => {
                    if (p.name != player_name) add_player(p.name, p.name, p.info);
                  });
                  Bus.$emit("playerInited");
                }
              });
              player_id = parseInt(param);
              if (_o2.player) {
                _o2.player.visible = true;
                if (_o2.role_id == role_id) {
                  _o2.add_lable_to_char(_o2.player, player_name);
                } else {
                  _o2.role_id = role_id;
                  for (let i = 0; i < _o2.player.children.length; i++) {
                    let o = _o2.player.children[i];
                    if (o.type.indexOf("Mesh") != -1) {
                      o.geometry.dispose();
                      o.material.dispose();
                      break;
                    }
                  }
                  _o2.scene.remove(_o2.player);
                  let url = _o2.char_urls[role_id];
                  let promise = _o2.import_object_url(url);
                  promise.then((obj3) => {
                    if (obj3) {
                      _o2.renew_all_uuid(obj3);
                      _o2.scene.add(obj3);
                      _o2.player = obj3;
                      _o2.add_lable_to_char(_o2.player, player_name);
                      if (obj3.type == "Group") {
                        _o2.char = obj3;
                      } else {
                        for (let i = 0; i < obj3.children.length; i++) {
                          if (obj3.children[i].type == "Group") {
                            _o2.char = obj3.children[i];
                            break;
                          }
                        }
                      }
                      //if (_o2.init_play) _o2.init_play();
                    }
                  });
                }
              }
              // var str = player_name;
              // var base = new Base64();
              // var result = base.encode(str);
              // ws.send("login," + result + "," + room_id.toString() + "," + role_id);
              // console.log("login," + result + "," + room_id.toString() + "," + role_id);

              ws.send("login," + result + "," + room_id.toString() + "," + role_id);
              bLoginSuccess = true;
            }
            if (cmd == "new_player") {
              let pos2 = param.indexOf(",");

              var list = received_msg.split(",");
              var num;
              if (list.length > 3) {
                num = parseInt(list[3]);
                let new_player_id = list[1];
                let new_player_name = list[1];
                //console.log(new_player_id,new_player_name);
                if (new_player_id != player_id) {
                  add_player(new_player_id, new_player_name, num);
                }
              }
            }
            if (cmd == "player_quit") {
              let arrStr = param.split(",");
              if (arrStr && arrStr.length == 2) {
                let player_id = arrStr[0];
                remove_player(player_id);
                Bus.$emit("num", -1);
              }
            }
            if (cmd == "pos") {
              let pos2 = param.indexOf(",");
              let left = param;
              if (pos2 > 0) {
                console.log(left);
                let strid = left.substr(0, pos2);
                let id_player = strid;
                left = left.substr(pos2 + 1, left.length - pos2 - 1);
                let arrStr = left.split(",");
                if (arrStr && arrStr.length == 4) {
                  let posx = parseFloat(arrStr[0]);
                  let posy = parseFloat(arrStr[1]);
                  let posz = parseFloat(arrStr[2]);
                  let angle = parseFloat(arrStr[3]);
                  update_player_position(id_player, posx, posy, posz, angle);
                }
                // pos2 = left.indexOf(",");
                // if (pos2 > 0) {
                //   let posx = parseInt(left.substr(0, pos2));
                //   left = left.substr(pos2 + 1, left.length - pos2 - 1);
                //   pos2 = left.indexOf(",");
                //   if (pos2 > 0) {
                //     let posy = parseInt(left.substr(0, pos2));
                //     left = left.substr(pos2 + 1, left.length - pos2 - 1);
                //     let angle = parseInt(left);
                //     // console.log(id_player,posx,posy,angle);
                //     update_player_position(id_player, posx, posy, angle);
                //   }
                // }
              }
            }
            let arrMsg = received_msg.split(",");
            if (arrMsg.length >= 2) {
              let cmd2 = arrMsg[1];
              if (cmd2 == "vmute") {
                if (arrMsg && arrMsg.length == 4) {
                  let name = arrMsg[2];
                  let val = arrMsg[3];
                  val = val == "true";
                  if (_o2.players) {
                    for (let i = 0; i < _o2.players.length; i++) {
                      const p = _o2.players[i];
                      if (p.id == name) {
                        if (val) {
                          p.player_video = _o2.add_video_stream_to_char(p.object, name);
                        }
                        if (p.player_video) {
                          p.player_video.display = val ? "block" : "none";
                        }

                        return;
                      }
                    }
                  }
                }
              }
            }

            if (received_msg.indexOf("transfer") >= 0) {
              let parms = received_msg.split(",");
              //console.log(arr[0],arr[1],arr[2],arr[3],arr[4]);
              if (parms && parms.length == 5) {
                let idStr = parms[0].substr(2, parms[0].length - 2);
                let id = Number(idStr);
                let x = Number(parms[2]);
                let z = Number(parms[3]);
                let a = Number(parms[4]);
                if (_o2.players) {
                  let len = _o2.players.length;
                  for (let i = 0; i < len; i++) {
                    let player = _o2.players[i];
                    if (player.id == id && player.object) {
                      player.target.x = x;
                      player.target.z = z;
                      player.target.y = (a * 3.1415) / 180;
                      player.object.position.set(x, 0, z);
                      break;
                    }
                  }
                }
              }
            }
          }
        };
        ws.onclose = function () {
          console.log("WebSocket closed...");
        };
      } else {
        console.log("NO support WebSocket!");
      }
    }
    var role_id;
    function login(name, roomid, rid) {
      that.$chat.login({ name, roomid, rid });
      // ws = new WebSocket("wss://o2vr.net:9103");
      // player_name = name;
      // room_id = rid;
      // role_id = roleId;

      //WebSocketInit();
    }
    _o2.login = login;

    function add_lable_to_char(object, name) {
      //return;
      let canvas = document.createElement("canvas");
      canvas.width = 160;
      canvas.height = 32;
      const drawingContext = canvas.getContext("2d");
      drawingContext.fillStyle = "#000000";
      drawingContext.globalAlpha = 0.8;
      drawingContext.fillRect(0, 0, 160, 32);
      drawingContext.globalAlpha = 1;
      drawingContext.fillStyle = "#ffffff";
      drawingContext.font = "24px Georgia";
      drawingContext.textAlign = "center";
      drawingContext.fillText(name, 80, 24);

      let map = new THREE.CanvasTexture(canvas);
      let sprite = new THREE.Sprite(
        new THREE.SpriteMaterial({ map: map, color: "#ffffff" })
      );
      sprite.position.set(0, 2000, 0);
      sprite.scale.set(600, 120, 1);
      sprite.material.depthWrite = false;
      object.add(sprite);
      return map;
    }
    _o2.add_lable_to_char = add_lable_to_char;

    function add_video_stream_to_char(obj, id) {
      return;

      if (!obj) return;
      let div = document.getElementById(id);
      console.log(div);
      if (div) {
        div.style.width = "400px";
        //div.display = "block";
        div.style.position = "absolute";
        if (!_o2.playerVideoPanels) {
          _o2.playerVideoPanels = [];
          _o2.frame_move_recall.push(() => {
            let cam = _o2.camera;
            let w = document.body.clientWidth;
            let h = document.body.clientHeight;
            //let matrirx = cam.projectionMatrix.clone().multiply(cam.matrixWorldInverse);
            _o2.playerVideoPanels.forEach((e) => {
              let parent = e.div;
              let offsetLeft = 0;
              let offsetTop = 0;
              while (parent) {
                offsetLeft += parent.offsetLeft;
                offsetTop += parent.offsetTop;
                parent = parent.offsetParent;
              }
              let pos = e.obj.position.clone();
              pos.y += 2200;
              pos.applyMatrix4(cam.matrixWorldInverse);
              let width =
                (300 * w) / ((-pos.z / cam.near) * (cam.near / Math.cos(cam.fov / 360)));
              pos.applyMatrix4(cam.projectionMatrix);
              let x = w / 2 + (w / 2) * pos.x - offsetLeft;
              let y = h / 2 + -offsetTop - (h / 2) * pos.y;
              console.log(e.div.parentNode.clientX);
              let z = width / 400;
              e.div.style.transform = `translate(-50%,-50%) matrix(${z},0,0,${z},${x},${y})`;
            });
          });
        }
        let data = {};
        data.obj = obj;
        data.div = div;
        _o2.playerVideoPanels.push(data);
        //div.style.transform = `translate(-50%,-50%) matrix(0.1,0,0,0.1,500,450)`;
        return div;
        // let css2dObj = new CSS3DSprite(div);
        // css2dObj.position.set(0,2200,0);
        // css2dObj.name = 'video';
        // obj.add(css2dObj);
        // return css2dObj;
        let arr = div.getElementsByTagName("video");
        console.log(arr.length);
        if (arr && arr.length > 0) {
          let video = arr[0];
          if (sprite) {
            sprite.material.map.dispose();
            sprite.material.map = new THREE.VideoTexture(video);
            return sprite;
          } else {
            let tex = new THREE.VideoTexture(video);
            sprite = new THREE.Sprite(
              new THREE.SpriteMaterial({ map: tex, color: "#ffffff" })
            );
            sprite.name = "videoSprite";
            obj.add(sprite);
            sprite.position.set(0, 2200, 0);
            sprite.scale.set(400, 300, 1);
            return sprite;
          }
        }
      }
    }
    _o2.add_video_stream_to_char = add_video_stream_to_char;

    function move_player_position(player, time_passed) {
      let dst = player.target.clone();
      let clip_name = "idle";
      dst.y = player.object.position.y;
      dst.sub(player.object.position);
      let len = dst.length();
      if (len > 1) {
        clip_name = "walk";
      }
      if (len <= 3000 * time_passed || len > 3000) {
        player.object.position.set(player.target.x, player.target.y, player.target.z);
      } else {
        let fullDst = player.target.clone().sub(player.object.position);
        dst.y = 0;
        let temp = fullDst.length() / dst.length();
        fullDst.normalize();
        fullDst.multiplyScalar(3000 * temp * time_passed);
        player.object.position.add(fullDst);
      }
      // let dst = player.target.clone();
      // let clip_name = "idle";
      // dst.y = 0;
      // dst.sub(player.object.position);
      // let len = dst.length();
      // if (len > 1) {
      //   clip_name = "walk";
      // }
      // if (len <= 3000 * time_passed) {
      //   player.object.position.set(player.target.x, 0, player.target.z);
      // } else {
      //   dst.y = 0;
      //   dst.normalize();
      //   dst.multiplyScalar(3000 * time_passed);
      //   player.object.position.add(dst);
      // }
      //角度
      let angle = player.angle;
      let current = player.object.rotation._y;
      let dst2 = (angle * 180) / 3.1415 - (current * 180) / 3.1415;
      if (Math.abs(dst2) > 180) {
        if (dst2 < 0) current = current - 3.1415 * 2;
        else current = current + 3.1415 * 2;
      }
      current = current * 0.9 + angle * 0.1;
      player.object.rotation.set(0, current, 0);
      //动作
      if (player.last_clip_name != clip_name) {
        if (!player.cold_down) player.cold_down = 1;
        player.cold_down = -1;
        if (player.cold_down <= 0) {
          console.log(player.cold_down, clip_name);
          _o2.play_skeleton_clip(player.char, clip_name, true);
          player.last_clip_name = clip_name;
          player.cold_down = 1000;
        }
      }
    }
    function move_with_colli(target, time_passed) {
      let radius = 200;
      if (!colli) {
        posPlayer.set(target.x, target.y, target.z);
        return;
      }
      let final = posPlayer.clone();
      let org = posPlayer.clone();
      org.y += colli_height;
      let target2 = target.clone();
      target2.y += colli_height;
      let dst = target2.clone();
      dst.sub(org);
      let len = dst.length() + radius;
      let raycaster = new THREE.Raycaster();
      raycaster.far = 1000;
      let dir = dst.clone();
      dir.normalize();
      org.sub(dir);
      raycaster.set(org, dir);
      var intersects = raycaster.intersectObjects(colli.children, true);
      if (intersects.length > 0) {
        if (intersects[0].distance < len) {
          dir.multiplyScalar(intersects[0].distance - radius);
          final.add(dir); //现在是退回到被拦截的位置，下面需要从切线方向滑行
          //滑行碰撞检测
          dir.normalize();
          let normal = intersects[0].face.normal.clone();
          normal.applyEuler(intersects[0].object.rotation);
          let spit = new THREE.Vector3(0, 1, 0);
          spit.cross(normal);
          spit.normalize();
          if (spit.dot(dir) < 0) spit = spit.multiplyScalar(-1); //旋转方向至行走方向的一边
          let len1 = dst.dot(spit);
          let len_final = len1 + radius;
          raycaster.set(org, spit);
          let intersects3 = raycaster.intersectObjects(colli.children, true);
          if (intersects3.length) {
            if (intersects[0].distance < len_final) {
              spit.multiplyScalar(intersects[0].distance - radius);
              final.add(spit);
            } else {
              spit.multiplyScalar(len_final - radius);
              final.add(spit);
            }
          } else {
            spit.multiplyScalar(len_final - radius);
            final.add(spit);
          }
        } else {
          final.set(target.x, posPlayer.y, target.z);
        }
      } else {
        final.set(target.x, posPlayer.y, target.z);
      }

      //下落
      down_speed = down_speed + 9800 * time_passed;
      let down_length = down_speed * time_passed;
      org.set(final.x, final.y + 500, final.z);
      dir.set(0, -1, 0);
      let y = posPlayer.y - down_length;
      let raycaster2 = new THREE.Raycaster();
      raycaster2.far = 1000;
      raycaster2.set(org, dir);
      intersects = raycaster2.intersectObjects(colli.children, true);
      if (intersects.length > 0) {
        //有碰撞到
        let pos = intersects[0].point;
        if (intersects[0].distance < 500 + down_length) {
          down_speed = 0;
          let dst = pos.y - posPlayer.y;
          if (dst < colli_height) {
            y = pos.y;
          }
        }
      }
      if (y < 0) {
        //落到了0平面
        y = 0;
        down_speed = 0;
      }
      final.y = y;
      posPlayer.set(final.x, final.y, final.z);
    }
    function set_edit_mode(bedit) {
      if (bEditMode == bedit) return;
      bEditMode = bedit;
      if (bEditMode) {
        //回到编辑模式
        if (old_video_object) {
          old_video_object.material.map = old_map;
          old_video_object = null;
          _o2.stop_video_tex();
        }
        if (old_audio_object) {
          old_audio_object = null;
          showroom.removeAudio();
        }
        if (bGuideActived) {
          bGuideActived = false;
          if (showroom.guide_obj) showroom.guide_obj.visible = false;
          toView(beforeguide);
          bGuideActived = false;
          bPaused = false;
        }
      } else {
        //进入预览
        showroom.prepare_run();
      }
      showroom.set_camera_controls(bEditMode);
    }
    _o2.set_edit_mode = set_edit_mode;

    //相机录制
    var bRecording = false;
    var bRecordPlaying = false;
    var m_begin_play_time;
    var record_frame_space = 1000; //录制间隔0.5秒
    var m_last_record_time;
    var record_cameras = [];
    var last_walk;
    function record_camera() {
      console.log("record_camera");
      bRecording = !bRecording;
      m_last_record_time = Date.now();
      if (bRecording) record_cameras = [];
    }
    function play_record_camera() {
      console.log("play_record_camera");
      if (bRecording) {
        return;
      }
      bRecordPlaying = !bRecordPlaying;
      if (bRecordPlaying) {
        m_begin_play_time = Date.now();
      }
    }
    function _load_camera_record(json) {
      record_cameras = [];
      if (!json) return;
      for (let i = 0; i < json.length; i++) {
        let str = json[i];
        let temp = str.split(",");
        if (temp.length == 6) {
          let key = {};
          key.eye = new THREE.Vector3(
            parseInt(temp[0]),
            parseInt(temp[1]),
            parseInt(temp[2])
          );
          key.pos = new THREE.Vector3(
            parseInt(temp[3]),
            parseInt(temp[4]),
            parseInt(temp[5])
          );
          record_cameras.push(key);
        }
      }
      // if (record_cameras.length==0) return null;
      // let obj=[];
      // for (let i=0;i<record_cameras.length;i++)
      // {
      //   let cam=record_cameras[i];
      //   let str=parseInt(cam.eye.x)+","+parseInt(cam.eye.y)+","+parseInt(cam.eye.z)+","+
      //   parseInt(cam.pos.x)+","+parseInt(cam.pos.y)+","+parseInt(cam.pos.z);
      //   obj.push(str);
      // }
      // return obj;
    }
    function get_record_json() {
      if (record_cameras.length == 0) return null;
      let obj = [];
      for (let i = 0; i < record_cameras.length; i++) {
        let cam = record_cameras[i];
        let str =
          parseInt(cam.eye.x) +
          "," +
          parseInt(cam.eye.y) +
          "," +
          parseInt(cam.eye.z) +
          "," +
          parseInt(cam.pos.x) +
          "," +
          parseInt(cam.pos.y) +
          "," +
          parseInt(cam.pos.z);
        obj.push(str);
      }
      return obj;
    }
    showroom._load_camera_record = _load_camera_record;
    showroom.get_record_json = get_record_json;
    showroom.record_camera = record_camera;
    showroom.play_record_camera = play_record_camera;

    showroom.record_camera = record_camera;
    showroom.play_record_camera = play_record_camera;
    function trigger_area_item(aht, bInside) {
      let type_trigger = aht.leave_type;
      let target_trigger = aht.leave_target;
      if (bInside) {
        type_trigger = aht.enter_type;
        target_trigger = aht.enter_target;
      }
      if (!bEditMode && type_trigger > 0) {
        if (type_trigger == 1) {
          //开启展项
          let showboxes = showroom.collect_showboxes();
          for (let i = 0; i < showboxes.length; i++) {
            let obj = showboxes[i];
            let sb = obj.showbox;
            if (sb.title == target_trigger) {
              console.log("开启展项", sb);
              trigger_showbox(sb, obj);
              break;
            }
          }
        } else if (type_trigger == 2) {
          //关闭展项
          let showboxes = showroom.collect_showboxes();
          for (let i = 0; i < showboxes.length; i++) {
            let obj = showboxes[i];
            let sb = obj.showbox;
            if (sb.title == target_trigger) {
              console.log("关闭展项", sb);
              if (old_audio_object == obj) {
                old_audio_object = null;
                showroom.removeAudio();
              }

              if (old_video_object == obj) {
                //停止视频播放
                old_video_object.material.map = old_map;
                old_video_object = null;
                _o2.stop_video_tex();
              }
              break;
            }
          }
        } else if (type_trigger == 3) {
          //开启动画
          let timelines = showroom.collect_animations();
          console.log("开启动画", timelines);
        }
      }
    }
    function check_hot_areas() {
      if (showroom.hot_areas.length) {
        for (let i = 0; i < showroom.hot_areas.length; i++) {
          let hotobj = showroom.hot_areas[i];
          let pos = posPlayer.clone();
          let wts = hotobj.matrixWorld.clone();
          wts.invert();
          pos.applyMatrix4(wts);
          let bInside = false;
          let lentest = 1000;
          if (hotobj.aht.bInside) {
            lentest = 1010;
          }
          if (hotobj.aht.type_geo == 0) {
            if (
              pos.x > lentest ||
              pos.x < -lentest ||
              pos.z > lentest ||
              pos.z < -lentest ||
              pos.y > lentest ||
              pos.y < -lentest
            ) {
              bInside = false;
            } else {
              bInside = true;
            }
          } else {
            if (pos.length() < lentest) {
              bInside = true;
            }
          }
          if (hotobj.aht) {
            if (hotobj.aht.bInside != bInside) {
              // console.log("area_trigger",bInside);
              hotobj.aht.bInside = bInside;
              trigger_area_item(hotobj.aht, bInside);
              if (bInside) {
                hotobj.material.opacity = 0.1;
              } else {
                hotobj.material.opacity = 0.5;
              }
            }
          }
        }
      }
    }
    function frame_move(time_passed) {
      check_hot_areas();
      if (showroom.guide_obj && showroom.guide_obj.visible) {
        let tm = Date.now();
        tm = (tm % 2000) / 1000; //0-2
        tm = Math.abs(tm - 1);
        showroom.guide_obj.position.y = tm * 1000;
      }
      let clip_name = "idle";
      if (_o2.players) {
        //更新多人在线其他角色位置
        for (let i = 0; i < _o2.players.length; i++) {
          let player = _o2.players[i];
          move_player_position(player, time_passed);
        }
      }

      //相机录制播放
      if (bRecordPlaying) {
        if (record_cameras.length > 1) {
          let num_frame = record_cameras.length;
          let dnow = Date.now();
          let dst = dnow - m_begin_play_time;
          let passed = dst / record_frame_space;
          passed = passed - num_frame * Math.floor(passed / num_frame);
          let lower = Math.floor(passed);
          let alpha = passed - lower;
          let key1 = record_cameras[lower];
          let key2 = key1;
          if (lower + 1 < record_cameras.length) {
            key2 = record_cameras[lower + 1];
          }
          let eye = key1.eye.clone();
          let pos = key1.pos.clone();
          eye.lerp(key2.eye, alpha);
          pos.lerp(key2.pos, alpha);
          posPlayer.set(pos.x, pos.y, pos.z);
          _o2.camera.position.set(eye.x, eye.y, eye.z);
          _o2.cameraControls.target.set(pos.x, pos.y + viewHeight, pos.z);

          if (_o2.player) {
            //更新角色和相机位置到posPlayer
            _o2.player.position.set(posPlayer.x, posPlayer.y, posPlayer.z);
          }

          if (!last_walk) last_walk = posPlayer.clone();
          else {
            last_walk.sub(posPlayer);
            let dst = last_walk.length();
            if (dst > 1) clip_name = "walk";
            last_walk = posPlayer.clone();
          }
          //朝向和动作
          if (_o2.player) {
            let movedir = posPlayer.clone();
            movedir.y += viewHeight;
            movedir.sub(eye);
            movedir.normalize();
            let angle = Math.asin(movedir.x);
            if (movedir.z < 0) angle = 3.1415 - angle;
            target_angle = angle;
            let current = _o2.player.rotation._y;
            let dst = (target_angle * 180) / 3.1415 - (current * 180) / 3.1415;
            if (Math.abs(dst) > 180) {
              if (dst < 0) current = current - 3.1415 * 2;
              else current = current + 3.1415 * 2;
            }
            current = current * 0.9 + target_angle * 0.1;
            _o2.player.rotation.set(0, current, 0);
            if (last_clip_name != clip_name) {
              _o2.play_skeleton_clip(_o2.char, clip_name, true);
              last_clip_name = clip_name;
            }
          }
        }

        return;
      }

      if (bEditMode && _o2.player) {
        _o2.player.visible = true;
      }
      //编辑模式也不隐藏角色了
      // if (bEditMode) {
      //   //编辑模式，直接隐藏角色
      //   if (_o2.player) {
      //     _o2.player.visible = false;
      //   }
      //   return;
      // } else {
      //   if (_o2.player) {
      //     _o2.player.visible = true;
      //   }
      // }

      if (bPaused) {
        //暂停模式，不隐藏角色，但是停止移动，更新动作为站立
        clip_name = "idle";
        moveDir.set(0, 0, 0);
        if (last_clip_name != clip_name) {
          _o2.play_skeleton_clip(_o2.char, clip_name, true);
          last_clip_name = clip_name;
        }
        return;
      }
      //处理角色移动
      let target = posPlayer.clone();
      let movedir = moveDir.clone();
      if (_o2.player) {
        if (movedir.length() <= 0.001) {
          movedir.set(0, 0, -1);
        }
        let dir = new THREE.Vector3();
        dir.set(0, 0, 0);
        dir.applyMatrix4(_o2.camera.matrixWorld);
        movedir.applyMatrix4(_o2.camera.matrixWorld);
        movedir.sub(dir);
        movedir.y = 0;
        movedir.normalize();
      }

      if (bAutoMove) {
        //还没有走到目的地
        clip_name = "walk";
        //先自动移动
        let dst = targetAutoMove.clone();
        dst.sub(posPlayer);
        let len = dst.length();
        if (len <= moveSpd * time_passed) {
          bAutoMove = false;
          target = targetAutoMove.clone();
        } else {
          dst.normalize();
          movedir.set(dst.x, dst.y, dst.z);
          dst.multiplyScalar(moveSpd * time_passed);
          target = posPlayer.clone();
          target.add(dst);
        }
      } else if (bKeyCtrl) {
        //然后键盘控制
        let move = moveDir.clone();
        move.normalize();
        let len = move.length();
        if (len > 0.001) {
          clip_name = "walk";
          let dir = new THREE.Vector3();
          dir.set(0, 0, 0);
          dir.applyMatrix4(_o2.camera.matrixWorld);
          move.applyMatrix4(_o2.camera.matrixWorld);
          move.sub(dir);
          move.y = 0;
          move.normalize();
          move.multiplyScalar(time_passed * moveSpd);
          target = posPlayer.clone();
          target.add(move);
        }
      }
      if (
        document.showroom.room.collision === true ||
        document.showroom.room.collision == undefined
      ) {
        move_with_colli(target, time_passed);
      } else {
        posPlayer.set(target.x, target.y, target.z);
      }
      if (_o2.player) {
        //更新角色和相机位置到posPlayer
        if (!_o2.eye_distance) {
          let v1 = _o2.camera.position.clone();
          let oldtarget = _o2.cameraControls.target.clone();
          v1.sub(oldtarget);
          _o2.eye_distance = v1.length();
        }
        let oldtarget = _o2.cameraControls.target.clone();
        let dir = _o2.camera.position.clone();
        dir.sub(oldtarget);
        let neweye = posPlayer.clone();
        neweye.add(dir);
        if (!_o2.fixed_view && _o2.view_length > 1000) {
          let target = new THREE.Vector3(
            posPlayer.x,
            posPlayer.y + viewHeight,
            posPlayer.z
          );
          let dir2 = dir.clone();
          dir2.normalize();
          let ray = new THREE.Raycaster(target, dir2, 10, _o2.view_length);
          ray.camera = _o2.camera;
          let rt = _o2.ray_test4(ray);
          let target_distance = _o2.view_length;
          if (rt.length) {
            target_distance = 100;
            _o2.eye_distance = rt[0].distance;
          }
          {
            target_distance = target_distance * 0.2 + _o2.eye_distance * 0.8;
            _o2.eye_distance = target_distance;
            let pos = target.clone();
            dir2.multiplyScalar(target_distance);
            pos.add(dir2);
            neweye.set(pos.x, pos.y - viewHeight, pos.z);
          }
        }
        _o2.player.position.set(posPlayer.x, posPlayer.y, posPlayer.z);
        _o2.camera.position.set(neweye.x, neweye.y + viewHeight, neweye.z);
        _o2.cameraControls.target.set(posPlayer.x, posPlayer.y + viewHeight, posPlayer.z);
      }

      ws_pos_cold_down = ws_pos_cold_down - time_passed;
      if (ws_pos_cold_down < 0) {
        ws_pos_cold_down = 0.3;
        send_position_to_server();
      }

      //朝向和动作
      if (_o2.player) {
        let angle = Math.asin(movedir.x);
        if (movedir.z < 0) angle = 3.1415 - angle;
        target_angle = angle;
        let current = _o2.player.rotation._y;
        let dst = (target_angle * 180) / 3.1415 - (current * 180) / 3.1415;
        if (Math.abs(dst) > 180) {
          if (dst < 0) current = current - 3.1415 * 2;
          else current = current + 3.1415 * 2;
        }
        current = current * 0.9 + target_angle * 0.1;
        _o2.player.rotation.set(0, current, 0);
        if (last_clip_name != clip_name) {
          _o2.play_skeleton_clip(_o2.char, clip_name, true);
          last_clip_name = clip_name;
        }
      }

      //相机录制
      if (bRecording) {
        let dnow = Date.now();
        let dst = dnow - m_last_record_time;
        if (dst > record_frame_space) {
          m_last_record_time = dnow;
          let frame = {};
          frame.eye = _o2.camera.position.clone();
          frame.pos = posPlayer.clone();
          record_cameras.push(frame);
          console.log(record_cameras.length);
        }
      }
      // if(bMoveCamera){

      // }
    }

    function onSwitchCameraFinish(x, y, z, xx, yy, zz) {
      if (_o2.player) {
        bPaused = false;
        bKeyCtrl = true;
        bAutoMove = false;
        _o2.player.position.set(x, 0, z);
        // posPlayer.x = _o2.player.position.x;
        // posPlayer.y = _o2.player.position.y;
        // posPlayer.z = _o2.player.position.z;
        let dir = new THREE.Vector3();
        dir.x = x - xx;
        dir.y = 0;
        dir.z = z - zz;
        dir = moveDir.normalize();
        let angle = Math.atan2(dir.z, dir.x);
        angle = (angle / 3.1415) * 180;
        send_transfer_position_to_server(angle);
      }
    }
    function send_transfer_position_to_server(angle) {
      if (!ws || !bLoginSuccess) return;
      let str =
        "transfer," +
        Math.ceil(posPlayer.x) +
        "," +
        Math.ceil(posPlayer.z) +
        "," +
        Math.ceil(angle);
      if (str != last_pos_send) {
        console.log(str);
        last_pos_send = str;
        ws.send(str);
      }
    }
    //编辑器
    function process_edit_key(e) {
      switch (e.key) {
        case "Delete":
          if (editType == 1) {
            if (showroom.selected_model) {
              showroom.remove_model(showroom.selected_model);
            }
          }
          if (editType == 4) {
            if (showroom.selected_model) {
              showroom.remove_hot(showroom.selected_model);
            }
          }
          break;
        case "`":
          //showroom.set_gizmo_mode(0);
          break;
        case "1":
          showroom.set_gizmo_mode(1);
          break;
        case "2":
          showroom.set_gizmo_mode(2);
          break;
        case "3":
          showroom.set_gizmo_mode(3);
          break;
      }
    }
    var beforeguide;
    var oldfar;
    function active_guide(bguid) {
      console.log(beforeguide, showroom.room.guide);
      if (!bguid) {
        if (oldfar) _o2.cameraControls.maxDistance = oldfar;
        if (showroom.guide_obj) showroom.guide_obj.visible = false;
        toView(beforeguide);
        bGuideActived = false;
        bPaused = false;
      } else {
        if (showroom.room.guide) {
          if (showroom.guide_obj) showroom.guide_obj.visible = true;
          oldfar = _o2.cameraControls.maxDistance;
          _o2.cameraControls.maxDistance = 100000;
          beforeguide = getView();
          toView(showroom.room.guide);
          bPaused = true;
          bGuideActived = true;
        }
      }
      let top = _o2.search_obj_by_name("top");
      if (top) {
        top.visible = !bguid;
      }
    }

    showroom.active_guide = active_guide;

    function setViewMode(value) {
      if (_o2.player) {
        // for (let i = 0; i < _o2.player.children.length; i++) {
        //   let e = _o2.player.children[i];
        //   e.visible = value;
        // }
        _o2.player.visible = value;
      }
      let targetDistance;
      if (value) {
        targetDistance = Math.min(2000, _o2.cameraControls.maxDistance);
      } else {
        targetDistance = _o2.cameraControls.minDistance;
      }
      let dir = _o2.camera.position.clone().sub(_o2.cameraControls.target).normalize();
      let targetPos = dir.multiplyScalar(targetDistance).add(_o2.cameraControls.target);
      let target = _o2.cameraControls.target;
      _o2.switch_camera(
        target.x,
        target.y,
        target.z,
        targetPos.x,
        targetPos.y,
        targetPos.z,
        0.5
      );
      //_o2.move
    }
    _o2.setViewMode = setViewMode;
    function onKeyDown(e) {
      if (bEditMode) {
        process_edit_key(e);
      } else {
        if (e.key == "g" || e.key == "G") active_guide(!bGuideActived);
      }
      if (bPaused) return;
      //console.log(document.showroom.moveFlag);
      if (!document.showroom.moveFlag) return;
      switch (e.key) {
        case "w":
        case "W":
          moveDir.z = -moveSpd;
          bAutoMove = false;
          break;
        case "a":
        case "A":
          moveDir.x = -moveSpd;
          bAutoMove = false;
          break;
        case "s":
        case "S":
          moveDir.z = moveSpd;
          bAutoMove = false;
          break;
        case "d":
        case "D":
          moveDir.x = moveSpd;
          bAutoMove = false;
          break;
      }
      if (document.o2) {
        document.o2.translate_camera(moveDir.x, moveDir.y, moveDir.z);
      }
    }

    Bus.$on("key", (target) => {
      switch (target) {
        case "q":
        case "Q":
          moveDir.y = moveSpd;
          bAutoMove = false;
          break;
        case "e":
        case "E":
          moveDir.y = -moveSpd;
          bAutoMove = false;
          break;
        case "w":
        case "W":
          moveDir.z = -moveSpd;
          bAutoMove = false;
          break;
        case "a":
        case "A":
          moveDir.x = -moveSpd;
          bAutoMove = false;
          break;
        case "s":
        case "S":
          moveDir.z = moveSpd;
          bAutoMove = false;
          break;
        case "d":
        case "D":
          moveDir.x = moveSpd;
          bAutoMove = false;
          break;
        case "all":
          moveDir.y = 0;
          moveDir.x = 0;
          moveDir.z = 0;
          break;
      }
      if (document.o2) {
        document.o2.translate_camera(moveDir.x, moveDir.y, moveDir.z);
      }
    });
    function onKeyUp(e) {
      if (bPaused) return;
      switch (e.key) {
        case "q":
        case "Q":
          moveDir.y = 0;
          break;
        case " ": //跳
          //down_speed=-3000;
          break;
        case "e":
        case "E":
          moveDir.y = 0;
          break;
        case "w":
        case "W":
          moveDir.z = 0;
          break;
        case "a":
        case "A":
          moveDir.x = 0;
          break;
        case "s":
        case "S":
          moveDir.z = 0;
          break;
        case "d":
        case "D":
          moveDir.x = 0;
          break;
      }
      if (document.o2) {
        document.o2.translate_camera(moveDir.x, moveDir.y, moveDir.z);
      }
    }

    Bus.$on("yaogan", (x, y) => {
      bAutoMove = false;
      moveDir.set(x, 0, y);
    });
    // function onDoubleClick(e) {
    //   if (that.flag) return;
    //   let intersects = document.o2.ray_test(e);
    //   if (document.o2 && intersects && intersects.length > 0) {
    //     let o2 = document.o2;
    //     if (o2.onDblClick) {
    //       o2.onDoubleClick(intersects[0].object);
    //     }
    //     console.log(intersects[0].object);
    //   }
    // }
    let container = document.getElementById("container");
    //container.addEventListener("click", onClick, false);
    container.addEventListener("pointerdown", onMouseDown, false);
    container.addEventListener("pointerup", onMouseUp, false);
    container.addEventListener("touchdown", onMouseDown, false);
    container.addEventListener("touchup", onMouseUp, false);
    container.addEventListener("mousemove", onMouseMove, false);
    //container.addEventListener("dblclick", onDoubleClick, false);
    document.addEventListener("keydown", onKeyDown, false);
    document.addEventListener("keyup", onKeyUp, false);
    document.o2.frame_move_recall.push(frame_move);
    document.o2.switch_camera_finish.push(onSwitchCameraFinish);
  },
  methods: {},
};
