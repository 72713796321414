import axios from "axios";
import QS from "qs";
import { Message } from 'element-ui'

axios.defaults.baseURL = "https://vryun.wh12345.net/api/";

axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded; charset=UTF-8";
export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params, headers: {
          "xx-token": localStorage.getItem("token") ? localStorage.getItem("token") : '',
        }
      })
      .then((res) => {

        resolve(res.data);

      })
      .catch((err) => {
        reject(err.data);
      });
  });
}

export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, QS.stringify(params), {
        headers: {
          "xx-token": localStorage.getItem("token") ? localStorage.getItem("token") : '',
        },
      })
      .then((res) => {


        resolve(res.data);

      })
      .catch((err) => {



        reject(err.data);
      });
  });
}

export function getuser(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        url,
        {
          params: params,
        },
        {
          headers: {
            "XX-Token": localStorage.getItem("token"),
            "XX-Device-Type": "pc",
          },
        }
      )
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}


export function load(url,formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, formData,
        {
          headers: {
            "XX-Token": localStorage.getItem("token"),
            "XX-Device-Type": "pc",
          },
        },
        {
          "Content-Type": "multipart/form-data",
        }
      )
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data);
      });

  });
}




export function update(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}

export default axios.create({
  baseURL: "",
  timeout: 10000,
});
