
export default {
  data() {
    return {
      cheatList: [{ name: "世界", id: 0 }],
      playerList: [],
      value: 0,
      input: "",
      groupFlag: true,
      chatFlag: true,
      msgList: [[]],
      name: "",
    };
  },
  methods: {
    chooseRoom(e) {
      this.value = e;
    },
    keyFlag(e) {
      console.log(123);
      this.Bus.$emit("keyFlag", e);
    },
    send() {
      console.log(123);
      if (!this.input) {
        this.$message.error("发送失败");
        return;
      }
      if (this.value == 0) {
        var e = `chats,${this.$chat.role.name},${this.input}`;
        this.$chat.send(e);
      } else {
        var e = `chat,${this.$chat.role.name},${this.cheatList[this.value].name},${
          this.input
        },${this.value}`;
        this.$chat.send(e);
      }
      this.input = "";
    },
    onlyChat(a, b) {
      console.log(a, b);
      if (this.$chat.role.name == b) {
        console.log("不能与自己聊天");
        return;
      }
      var found = this.cheatList.filter((el) => el.name === b).length > 0; // .length returns 1 if id exist in an array

      if (!found) {
        this.cheatList.push({
          name: a,
        });
      }
      var value = this.cheatList.findIndex((item) => item.name === b);
      this.value = value;
    },

    to_footer() {
      var div = document.getElementById("chat");
      setTimeout(() => {
        div.scrollTop = div.scrollHeight;
      }, 100);
    },
  },
  created() {
    var that = this;
    document.onkeydown = function (el) {
      if (el.keyCode == 13) {
        if (that.chatFlag) {
          that.send();
          that.$refs.input.blur();
        }
      } else {
      }
    };

    this.Bus.$on("msgList", (e) => {
      this.msgList = e;
      this.to_footer();
    });

    this.Bus.$on("player", (e) => {
      this.playerList = e.players;
    });

    this.Bus.$on("reciveMsg", (e) => {
      this.name = this.$chat.role.name;
      var found = this.cheatList.filter((el) => el.name === e.from).length > 0;
      if (!found) {
        this.msgList.push([]);
        this.cheatList.push({
          name: e.name,
          id: e.from,
        });
      }
      var value = this.cheatList.findIndex((item) => item.name === e.from);
      this.value = value;
      this.msgList[value].push({
        name: e.name,
        msg: e.msg,
      });
      this.$chat.msgList = this.msgList;
      this.to_footer();
    });
  },
};
