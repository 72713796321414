import { get, post, getuser } from "./request";


export const add_popular = (params) => get('/scene/popular', params)//访问统计
export const get_scene_user = (params) => get('/scene/scene_user', params) //
export const getScene = (params) => get("/scene/get_solo", params); //获取单个用户场景信息
export const get_scene_interacts = (params) => get("/scene/interacts", params); //获取用户点赞评论信息
export const set_scene_thumbs = (params) => post('/scene/thumbs', params) //点赞
export const set_scene_comment = (params) => post('/scene/comment', params) //评论
export const rtc_timer = (params) => post('/scene/rtc_timer', params)
export const search_time = (params) => get('/scene/search_time', params)
export const update_time = (params) =>
  get("https://api.mvpmeta.com/api/vryun/public/update_time", params)
export const qrcode = (params) =>
  get("https://api.mvpmeta.com/api/vryun/public/qrcode", params) //小程序二维码
export const statistics = (params) => get("https://api.mvpmeta.com/api/vryun/public/statistics", params)//记录
export const config = (params) => get("https://api.mvpmeta.com/api/vryun/public/wechat_config", params)//记录
export const get_solo_media = (params) => get("/media/get_solo", params)




