
import { MessagePlugin } from 'tdesign-vue'
export default {
  data() {
    return {
      list: [],
      yuyinFlag: false,
      shipinFlag: false,
      screenFlag: false,
      playValue: 999,
      shareFlag: false,
      sharename: '',
      qpFlag: false,
      localFlag: false,
      imgFlag: false,
      two: false,
      jinyanflag: false,

      hostguest: false,
      shrinkFlag: false,
      suo: false
    }
  },
  mounted() {
    this.Bus.$on('spliu', (e) => {
      console.log(e)
    })

    this.Bus.$on('mute', (e) => {
      if (!e) {
        // MessagePlugin.error({
        //   placement: 'top-right',
        //   content: '您的语音已关闭',
        //   duration: 1000
        // })
        this.yuyinFlag = false
      } else {
        // MessagePlugin.success({
        //   placement: 'top-right',
        //   content: '您的语音已开启',
        //   duration: 5000
        // })
        this.yuyinFlag = true
      }
    })
    this.Bus.$on('vmute', (e) => {
      if (!e[0]) {
        // MessagePlugin.error({
        //   placement: 'top-right',
        //   content: '您的视频已关闭',
        //   duration: 1000
        // })
        this.shipinFlag = false
      } else {
        // MessagePlugin.success({
        //   placement: 'top-right',
        //   content: '您的视频已开启',
        //   duration: 1000
        // })
        this.shipinFlag = true
      }
    })
  },
  methods: {},
  created() {
    this.Bus.$on('clientOut', (e) => {
      console.log('退出房间')
      document.getElementById('local_stream').innerHTML = ''
      this.list = []
      this.$client.loginOut()
      MessagePlugin.success({
        placement: 'top-right',
        content: '您已退出语音区间',
        duration: 1000
      })
      this.$client.clientFlag = false
      this.shareFlag = false
      this.yuyinFlag = false
      this.shipinFlag = false
      this.screenFlag = false
      this.imgFlag = false
    })
    this.Bus.$on('localFlag', (e) => {
      this.localFlag = e
      console.log(this.localFlag)
    })
    this.Bus.$on('listChange', (e) => {
      if (e.length > 0) {
        // this.list = e;
        e.forEach((stream) => {
          this.list.find(function(value) {
            if (value.userId == stream.userId) {
              value.audioMuted = stream.audioMuted
              value.hasAudio = stream.hasAudio
              value.hasVideo = stream.hasVideo
              value.videoMuted = stream.videoMuted
            }
          })
        })
      }
    })
    this.Bus.$on('addlist', (e) => {
      this.list.push({
        userId: e.getUserId(),
        audioMuted: true,
        videoMuted: true,
        hasAudio: false,
        hasVideo: false
      })
      var name = e.getUserId()
      var node = document.createElement('div')
      node.setAttribute('id', name)
      node.setAttribute('class', 'videoBox1')
      var shadow = document.getElementById('shadow')
      shadow.appendChild(node)
      e.play(name)

      console.log(e)
    })
    this.Bus.$on('removelist', (e) => {
      console.log(e, 'remove')
      var box = document.getElementById(e.getUserId())
      box.remove()
      this.list = this.list.filter((stream) => {
        return stream.userId !== e.getUserId()
      })
    })

    //远方流列表
    this.Bus.$on('micError', () => {
      MessagePlugin.warning({
        placement: 'top',
        content: '检测到您没有麦克风,语音功能将失效',
        duration: 3000
      })
    })
    //摄像机报错
    this.Bus.$on('cameraError', () => {
      MessagePlugin.warning({
        placement: 'top',
        content: '检测到您没有摄像头,视频功能将失效',
        duration: 3000
      })
    })
    //屏幕分享报错

    this.Bus.$on('shareScreen', (e) => {
      this.shareFlag = true
      this.sharename = e.sharename
    })

    this.Bus.$on('shareClose', (e) => {
      this.shareFlag = false
      this.sharename = ''
      this.screenFlag = false
    })

    //屏幕分享

    this.Bus.$on('login', () => {
      MessagePlugin.success({
        placement: 'top-right',
        content: '您已加入语音区间',
        duration: 1000
      })

      this.$client.clientFlag = true
    })
  }
}
